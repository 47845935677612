import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState, useRef, useMemo } from "react";
import Instance from "../../Utils/Axios";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "../../Utils/index";
import JoditEditor from "jodit-react";
import { Placeholder } from "react-bootstrap";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import EditorialRoom from "./EditorialRoom";
import axios from "axios";

const PressReleasePublishUpload = () => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [record, setRecord] = useState(EditorialRoom);
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  // Handle file selection
  // const handleFileChange = (event) => {
  //   setSelectedFile(event.target.files[0]);

  //   if (!event.target.files[0]) {
  //     alert("Please select a file first!");
  //     return;
  //   } else {
  //     console.log(event.target.files[0]);
  //     uploadFile();
  //   }
  // };

  // const handleUpload = () => {
  //   if (!selectedFile) {
  //     alert("Please select a file first!");
  //     return;
  //   } else {
  //     uploadFile();
  //   }
  // };

  // const uploadFile = () => {
  //   const token = localStorage.getItem("adminToken");
  //   const formData = new FormData();
  //   console.log("selected file", selectedFile);
  //   formData.append("adminToken", token);
  //   formData.append("file", selectedFile);
  //   try {
  //     const response = axios.post(
  //       "https://pradmin.webandapi.com//siteadmin/upload/document",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     console.log("File uploaded successfully:", response.data);
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   }
  // };

  const handleFileChangeLogo = (event) => {
    const token = localStorage.getItem("adminToken");
    const file = event.target.files[0];

    console.log(file.name);
    setFile(file);
    //alert('h');
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("adminToken", token);
    formDataToSubmit.append("fileUpload", file);

    axios
      .post(
        "https://pradmin.webandapi.com//siteadmin/upload/document",
        formDataToSubmit,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response?.data?.resCode == "200") {
          // setFormData((prevData) => ({
          //   ...prevData,
          //   logo: response?.data?.documentId,
          // }));

          console.log("heyyyyyyyyyyy", response?.data?.documentId);
        }
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching network list:", error);
      });
    console.log(file);
  };

  return (
    <>
      <div className="admin_editorial_layout">
        <div className="page_heading">
          <h4>Upload Report</h4>
          <Link to="/admin/editorial/publish_press_release">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="add_new_editorial">
          <Form
          //  onSubmit={}
          >
            <Row>
              <Col className="col-12 custom_col_add_editorial mb-4">
                <Form.Label>Upload Report</Form.Label>
                <p>
                  Upload Report for this title :-
                  <span>
                    <Form.Label>
                      Citizens Commission on Human Rights Featured in Nashville
                    </Form.Label>
                  </span>
                </p>
              </Col>

              <Col className="col-12 custom_col_add_editorial  mb-3">
                <div className=" ">
                  <p style={{ fontSize: "16px" }}>PDF Upload</p>
                </div>

                {/* <p style={{color:"red",fontSize:"16px",fontWeight:"700"}}>You can upload image only JPEG & JPG format.</p> */}

                <div class="input-group  ">
                  <Form.Control
                    type="file"
                    class="form-control"
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    onChange={handleFileChangeLogo}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="col-12 custom_col_add_editorial mb-3 ">
                <div className=" ">
                  <p style={{ fontSize: "16px" }}>Excel Upload</p>
                </div>

                {/* <p style={{color:"red",fontSize:"16px",fontWeight:"700"}}>You can upload image only JPEG & JPG format.</p> */}

                <div class="input-group  ">
                  <Form.Control
                    type="file"
                    class="form-control"
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                  />
                </div>
              </Col>
              <Col className="col-12 custom_col_add_editorial mb-2 ">
                <p
                  style={{
                    color: "red",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  you have already uploaded report. you can updated report
                  .select file and click upload report. &nbsp;
                  <span style={{ color: "#7d090f" }}>
                    upload only pdf|csv|xls file
                  </span>
                </p>
              </Col>
              <Col className="col-12 custom_col_add_editorial mb-3 ">
                <p style={{ color: "#323232", fontSize: "14px" }}>
                  Click download button and get previous uploaded report PDF
                  &nbsp;
                  <span className="" style={{ color: "#7d090f" }}>
                    <img
                      src="/build/download_icon.png"
                      style={{ height: "22px", width: "22px" }}
                    />
                  </span>
                </p>
              </Col>
            </Row>

            <Row className="pb-5 ">
              <Col className=" ">
                <button
                  className="add_editorial_submit_btn"
                  type="submit"
                  style={{ width: "200px" }}
                >
                  Upload Report
                </button>
              </Col>
            </Row>
          </Form>
          {/* {error && <p className="error_message">{error}</p>} */}
        </div>
      </div>
    </>
  );
};

export default PressReleasePublishUpload;
