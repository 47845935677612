import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState, useRef, useMemo } from "react";
import Instance from "../../Utils/Axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../Utils/index";
import JoditEditor from "jodit-react";
import { Placeholder } from "react-bootstrap";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import EditorialRoom from "./EditorialRoom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import parse from "html-react-parser";
import axios from "axios";

const PressReleaseView = () => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const { id } = useParams();
  // console.log("id --------", id);

  const [show, setShow] = useState(false);
  const [editorData, setEditorData] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [companyObj, setCompanyObj] = useState({});
  const [userList, setUserList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [showStatus, setShowStatus] = useState(false);
  const [countStatus, setCountStatus] = useState(0);
  const [count, setCount] = useState(false);

  const [historyData, setHistoryData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [imageId, setImageID] = useState("");

  const [formData, setFormData] = useState({
    id: id,
    userId: "",
    packageId: "",
    title: "",
    description: "",
    showContactDetails: "",
    addNote: "",
    image: "",
    status: "",
    categoryId: 2,
    company: 3513,
    packageName: "",
    actionDate: "",
    statusDate: "",
    statusNote: "",
  });
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const config = {
    Placeholder: "start typing ..",
  };
  const fetchPrHistory = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/pressrelease/history`, {
        adminToken: token,
        id: id,
      });
      // console.log("Response from user details API:", response?.data);
      setHistoryData(response?.data?.message);
      setTempData(response?.data?.message);

      setError("");
    } catch (error) {
      setError("Failed to fetch PR History details");
      // console.error("Error fetching user details for edit:", error);
    }
  };
  useEffect(() => {
    fetchPrHistory();
  }, [id]);

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...historyData]);
    } else {
      const filteredItems = historyData.filter(
        (item) =>
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.user &&
            item.user.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.createdOn &&
            item.createdOn.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.note &&
            item.note.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await Instance.post(`/siteadmin/user/userDetails`, {
          adminToken: token,
          userId: id,
        });
        // console.log("Response from user details API:", response?.data);
        setUserList(response?.data);

        setError("");
      } catch (error) {
        setError("Failed to fetch user details");
        // console.error("Error fetching user details for edit:", error);
      }
    };

    fetchUserDetails();
  }, [id]);

  const fetchCompanyList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/user/company/getList", {
      adminToken: token,
      status: "active",
    })
      .then((response) => {
        setCompanyList(response?.data?.message);

        setError("");
        // console.log("check Company  data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the  Company data");
        // console.error("Error fetching Company list:", error);
      });
  };
  useEffect(() => {
    fetchCompanyList();
  }, []);

  const handleComapnyChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    var companyDetail = companyList.find(
      (obj) => obj.companyId === e.target.value
    );

    setCompanyObj(companyDetail);
    // console.log("company ---------------------", companyDetail);
  };

  const fetchCountryList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/common/countryList", {
      adminToken: token,
      status: "",
    })
      .then((response) => {
        setCountryList(response?.data?.message);

        setError("");
        // console.log("check country  data", response?.data?.message);
      })

      .catch((error) => {
        setError("Failed to fetch the country data");
        console.error("Error fetching country list:", error);
      });
  };
  useEffect(() => {
    fetchCountryList();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const fetchEditorialDetails = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await Instance.post(
          `/siteadmin/pressrelease/details`,
          {
            adminToken: token,
            id: id,
          }
        );

        setStatusData([response?.data?.message]);
        setFormData({
          id: id,
          userId: response?.data?.message?.userId,
          packageId: response?.data?.message?.packageId,
          title: response?.data?.message?.title,
          description: response?.data?.message?.description,
          showContactDetails: response?.data?.message?.showContactDetails,
          addNote: response?.data?.message?.addNote,
          image: response?.data?.message?.image,
          status: response?.data?.message?.status,
          categoryId: response?.data?.message?.countryId,
          company: response?.data?.message?.companyId,
          packageName: response?.data?.message?.packageName,
          actionDate: response?.data?.message?.actionDate,
          statusDate: response?.data?.message?.statusDate,
          statusNote: response?.data?.message?.statusNote,
        });

        setEditorData(response?.data?.message?.description);

        // let arr = response?.data?.message?.imageDetails.map((obj) => {
        //   return obj.id;
        // });
        // var mainArr = [];
        // galleryList.map((obj) => {
        //   if (arr.includes(obj.value)) {
        //     mainArr.push(obj);
        //   }
        // });

        // setSelectedImg(mainArr);

        var companyDetail = companyList.find(
          (obj) => obj.companyId === response?.data?.message?.companyId
        );

        setCompanyObj(companyDetail || {});
        setError("");
      } catch (error) {
        setError("Failed to fetch editorial details");
        console.error("Error fetching editorial details for edit:", error);
      }
    };

    fetchEditorialDetails();
  }, [id, companyList]);

  const columns = [
    {
      name: "PR Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Status Note",
      selector: (row) => row.note,
      sortable: true,
    },
    {
      name: "Status Date",
      selector: (row) => row.createdOn,
      sortable: true,
    },
    {
      name: "who's did action",
      selector: (row) => row.user,
      sortable: true,
    },
  ];

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );

    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(historyData[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const data = [
    {
      PrStatus: "Published",
      StatusNote: "Approved",
      StatusDate: "2024/07/04 10:07",
      Action: "tushar",
    },
  ];
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };
  const handleCountryChange = (e) => {
    setCompanyObj({
      ...companyObj,
      [e.target.name]: e.target.value,
    });

    // console.log("company ---------------------", companyDetail);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // alert("hlo");
    formData.image = imageId;
    if (showStatus == false) {
      setShowStatus(true);
      return;
    } else {
      formData.description = editorData;
      // formData.image = imageString;s

      if (countStatus === 1) {
        formData.status = "PUBLISHED";
      } else if (countStatus === 2) {
        formData.status = "PENDING";
      } else {
        formData.status = "OPEN";
      }

      console.log("updated data is : ", formData);
      alert("hii");

      try {
        const token = localStorage.getItem("adminToken");
        const response = await Instance.post(`/siteadmin/pressrelease/update`, {
          id: id,
          userId: formData.userId,
          packageId: formData.packageId,
          title: formData.title,
          description: formData.description,
          showContactDetails: formData.showContactDetails,
          addNote: formData.addNote,
          image: formData.image,
          status: formData.status,
          categoryId: parseInt(formData.categoryId),
          company: formData.company,
          actionDate: formData.actionDate,
          statusDate: formData.statusDate,
          statusNote: formData.statusNote,

          adminToken: token,
        });
        console.log("Response from updateEditorial API:", response.data);

        if (response.data.resCode === "200") {
          showToast("Updated Succesfully!", "success", "success");
          setTimeout(() => {
            navigate("/admin/editorial");
          }, 1000);
        } else {
          setError(response.data.resMessage);
        }
      } catch (error) {
        setError("Failed to update Editorial details");
        console.error("Error updating Editorial details:", error);
      }
    }
  };

  if (!formData) {
    return <p>Loading</p>;
  }

  const handleAddGallery = (documentUrl) => {
    // e.preventDefault();
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/gallery/create", {
      adminToken: token,
      ...{
        name: file.name,
        documentUrl: documentUrl,
        userId: userList?.userProfile?.userId,
      },
    })
      .then((response) => {
        if (response.data.resCode === "100") {
          // showToast("Already a member", "error", "error");
          setError(response.data.resMessage);
          // console.log("gallery addition failed:", response.data);
        } else {
          console.log("Image added successfully:", response?.data);
          setImageID(response?.data?.id);
          // showToast("gallery added successfully", "success", "success");
          setError("");
          // setTimeout(() => {
          //   navigate("/editorial/category_press_release");
          // }, 1000);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to add Image");
        console.error("Error adding Image :", error);
      });
  };

  const handleFileChangeLogo = (event) => {
    const file = event.target.files[0];

    setFile(file);

    console.log(file);
  };

  const submitImage = () => {
    if (!file) {
      showToast("Please Select Image", "error", "error");
    } else {
      const formDataToSubmit = new FormData();
      const token = localStorage.getItem("adminToken");
      formDataToSubmit.append("adminToken", token);
      formDataToSubmit.append("fileUpload", file);

      axios
        .post(
          "https://pradmin.webandapi.com//siteadmin/upload/document",
          formDataToSubmit,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response?.data?.resCode == "200") {
            handleAddGallery(response?.data?.documentUrl);

            console.log("heyyyyyyyyyyy", response?.data?.documentUrl);
            showToast("Image Inserted !", "success", "success");
          }
        })
        .catch((error) => {
          setError("Failed to fetch the data");
          console.error("Error fetching network list:", error);
        });
    }
  };

  return (
    <>
      {count === false ? (
        <div className="admin_editorial_layout">
          <div className="page_heading">
            <h4>View Press Release</h4>
            <Link to="/admin/editorial">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
          <div className="add_new_editorial">
            <Form onSubmit={handleSubmit}>
              <Row
                className="  mb-4  rounded flex align-items-center"
                style={{
                  backgroundColor: "#595fde",
                  color: "white",
                  fontSize: "1.25rem",
                }}
              >
                <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 text-center pt-2 ">
                  <p>Username: {userList?.userProfile?.firstName}</p>
                </Col>

                <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 text-center pt-2 ">
                  <p>{userList?.userProfile?.companyName}</p>
                </Col>
                <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 text-center pt-2 ">
                  <p>Status: {formData.status}</p>
                </Col>
              </Row>
              {/* <Row className="mb-4 ">
                <Col
                  className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-center  rounded "
                  style={{
                    backgroundColor: "#595fde",
                    color: "white",
                    fontSize: "1.25rem",
                  }}
                >
                  <div>Username : {userList?.userProfile?.firstName}</div>
                  <div>{userList?.userProfile?.companyName}</div>
                  <div>Status: {formData.status}</div>
                </Col>
              </Row> */}
              {/* <Row className="mb-3">
              <Col
                className="col-xl-4 col-lg-4 col-md-6 col-sm-12 text-center  rounded"
                style={{
                  backgroundColor: "#595fde",
                  color: "white",
                  fontSize: "1.25rem",
                }}
              >
                <div>Status: Published</div>
              </Col>
            </Row> */}
              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Title of the release</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    placeholder="ScottyTheAi Going Multi-chain, in Efforts to Complete the Blockchain Takeover."
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Press Release body</Form.Label>
                  {/* <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {}}
                  style={{ height: "500px" }}
                  className="jodit-wysiwyg"
                /> */}
                  <div className="CKEditorComponent">
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorData}
                      onChange={handleEditorChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="col-12 custom_col_add_editorial ">
                  <Form.Label>
                    Insert Image & Copy Image URL &nbsp;
                    <div className="btn">
                      <img
                        src="/build/gallery_logo.png"
                        style={{
                          height: "50px",
                          width: "50px",
                          backgroundColor: "#595fde",
                        }}
                        alt="image"
                        onClick={() => setShow(true)}
                      />
                    </div>
                    &nbsp;
                    <span
                      style={{
                        color: "red",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      0 Image Uploaded x
                    </span>
                  </Form.Label>
                </Col>

                <Col
                  className="col-12 custom_col_add_editorial mb-4"
                  style={{ display: show === true ? "block" : "none" }}
                >
                  <Card>
                    <Card.Body>
                      <div className="button_grp">
                        <p style={{ fontSize: "16px" }}>Add Image & Copy URL</p>
                        <p
                          className="text-white px-2"
                          style={{
                            backgroundColor: "#ff5450",
                            lineHeight: "center",
                            fontSize: "15px",
                            fontWeight: "normal",
                          }}
                          onClick={() => setShow(false)}
                        >
                          X
                        </p>
                      </div>
                      <div>
                        <Form.Label className=" mb-1 ">Add Image</Form.Label>
                      </div>

                      <p
                        style={{
                          color: "red",
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                      >
                        You can upload image only JPEG & JPG format.
                      </p>

                      <div class="input-group mb-3">
                        <Form.Control
                          type="file"
                          class="form-control"
                          id="inputGroupFile04"
                          aria-describedby="inputGroupFileAddon04"
                          aria-label="Upload"
                          onChange={handleFileChangeLogo}
                          // onChange={(e) => console.log(e.target.files[0])}
                        />
                      </div>
                      <strong
                        className="btn btn-info text-white py-1"
                        onClick={submitImage}
                      >
                        Insert Image
                      </strong>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col xs="12" className="custom_col_add_editorial mb-4">
                  <Form.Label>Select Company</Form.Label>
                  <Form.Control
                    as="select"
                    name="company"
                    value={formData.company}
                    onChange={handleComapnyChange}
                  >
                    <option value="">Select Company</option>
                    {companyList.map((val) => {
                      return (
                        <>
                          <option value={val.companyId}>{val.name}</option>
                        </>
                      );
                    })}{" "}
                  </Form.Control>
                </Col>
              </Row>
              <Row
                className="text-center pt-2 "
                style={{ color: "#2e61f2", fontWeight: "700" }}
              >
                <Col>OR</Col>
              </Row>
              <Row className="pb-2" style={{ fontSize: "40px" }}>
                <Col>
                  <Form.Label>Sender Details / Company Details </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Contact Person Name :-</Form.Label>
                  <Form.Control
                    type="text"
                    name="contactPerson"
                    value={companyObj.contactPerson}
                    // onChange={handleChange}
                    placeholder="john Smith"
                    required
                  ></Form.Control>
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Company Name :-</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={companyObj.name}
                    // onChange={handleChange}

                    placeholder="ZXPREWIRE*"
                    required
                  ></Form.Control>
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Email :-</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={companyObj.email}
                    // onChange={handleChange}

                    placeholder="info@zexprwire.com*"
                    required
                  ></Form.Control>
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Phone :-</Form.Label>
                  <Form.Control
                    type="number"
                    name="mobile"
                    value={companyObj.mobile}
                    // onChange={handleChange}
                    placeholder="1-545-XXXXX"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Company Website :-</Form.Label>
                  <Form.Control
                    type="text"
                    name="website"
                    value={companyObj.website}
                    // onChange={handleChange}

                    placeholder="http://www.google.com"
                    required
                  ></Form.Control>
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Company Address :-</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={companyObj.address}
                    // onChange={handleChange}

                    placeholder="1A-LANE.."
                    required
                  ></Form.Control>
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>City & State (Both mandate) :-</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    value={companyObj.state}
                    // onChange={handleChange}

                    placeholder="California*"
                    required
                  ></Form.Control>
                </Col>
                <Col className="col-6 custom_col_add_editorial mb-4">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    name="country"
                    value={companyObj.country}
                    onChange={(e) => handleCountryChange(e)}
                    // onChange={handleChange}
                  >
                    <option value=""> </option>
                    {countryList.map((val) => {
                      return (
                        <>
                          <option value={val.countryName}>
                            {val.countryName}
                          </option>
                        </>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Row>
              <Row></Row>
              <Row>
                <Col className="col-6 custom_col_add_editorial ">
                  <Form.Label>
                    Do you want to show contact details on Press Release?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="showContactDetails"
                    value={formData.showContactDetails}
                    onChange={handleChange}
                  >
                    <option value="Do you want to show contact details on Press Release?">
                      {" "}
                      Do you want to show contact details on Press Release?
                    </option>
                    <option value="YES">Yes</option>
                    <option value="NO">No</option>
                  </Form.Control>
                  <p
                    className="pt-2 "
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "green",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    [ This means if you select "NO" then your contact details
                    will not be visible in our newsroom, but it will be
                    syndicated and visible on other news Platforms ]
                  </p>
                </Col>
                <Col className="col-6 custom_col_add_editorial ">
                  <Form.Label>Select date & time</Form.Label>

                  <Form.Control
                    type="datetime-local"
                    id="datetime"
                    name="actionDate"
                    value={formData.actionDate}
                    onChange={handleChange}
                    // onChange={(e) => console.log(e.target.value)}
                    required
                  ></Form.Control>
                </Col>
                <Col className="col-6 custom_col_add_editorial ">
                  <Form.Group>
                    <Form.Label>See note written by customer.</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="addNote"
                      value={formData.addNote}
                      // onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {showStatus === true ? (
                <Row>
                  <Col className="col-12">
                    <Card>
                      <Card.Body>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label style={{ color: "blue" }}>
                            Note for status
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            name="statusNote"
                            value={formData.statusNote}
                            onChange={handleChange}
                            placeholder="Enter Note"
                            required
                          />
                        </Form.Group>
                        <h6 style={{ color: "red" }} className="mt-0cd ">
                          * Please enter note
                        </h6>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label style={{ color: "blue" }}>
                            Date for status
                          </Form.Label>
                          <Form.Control
                            type="datetime-local"
                            id="datetime"
                            name="statusDate"
                            value={formData.statusDate}
                            onChange={handleChange}
                            // onChange={(e) => console.log(e.target.value)}
                            required
                          ></Form.Control>
                        </Form.Group>
                        <h6 style={{ color: "red" }} className="mt-0">
                          * Please enter date & time
                        </h6>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              ) : null}

              <Row className="pb-5 text-center">
                {countStatus === 1 || countStatus === 0 ? (
                  <Col className=" ">
                    <button
                      className="add_editorial_submit_btn"
                      type="submit"
                      style={{ width: "200px" }}
                      onClick={() => setCountStatus(1)}
                    >
                      Edit & Publish
                    </button>
                  </Col>
                ) : null}

                {countStatus === 2 || countStatus === 0 ? (
                  <Col className=" ">
                    <button
                      className="add_editorial_submit_btn"
                      type="submit"
                      style={{ width: "200px" }}
                      onClick={() => setCountStatus(2)}
                    >
                      Back to Pending
                    </button>
                  </Col>
                ) : null}
                <Col className=" ">
                  <button
                    className="add_editorial_submit_btn"
                    type="button"
                    style={{ width: "200px" }}
                    onClick={() => setCount(!count)}
                  >
                    Preview
                  </button>
                </Col>
              </Row>

              <Card className="shadow ">
                <Card.Body>
                  <Card.Title
                    className="p-2 rounded "
                    style={{ backgroundColor: "#595fde", color: "white" }}
                  >
                    Press Release History :- Badr Jafar awarded Honorary Fe...
                  </Card.Title>

                  <Card.Header className="d-md-flex justify-content-between align-items-center">
                    <div className="editorial_search">
                      <label>
                        Search:
                        <input
                          type="search"
                          placeholder=""
                          aria-controls="datatable"
                          onChange={filterData}
                        ></input>
                      </label>
                    </div>
                    <div className="d-md-flex">
                      <button
                        onClick={(e) => downloadCSV(historyData)}
                        className="btn btn-export mx-3"
                      >
                        <img
                          src="/build/img/Icons/export.svg"
                          alt="zex pr wire Logo"
                          width={25}
                          height={25}
                        />
                      </button>
                    </div>
                  </Card.Header>

                  <DataTable
                    columns={columns}
                    data={tempData}
                    pagination
                    defaultSortFieldId={1}
                    responsive={true}
                    defaultSortAsc={true}
                    //  actions={actionsMemo}
                  />
                </Card.Body>
              </Card>
            </Form>
            {/* {error && <p className="error_message">{error}</p>} */}
          </div>
        </div>
      ) : (
        <div className="admin_editorial_layout">
          <div className="page_heading">
            <h4>Preview Press Release</h4>
            <Link onClick={() => setCount(!count)}>
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>

          <div className="add_new_editorial">
            <Card.Text className="mb-4">
              <div>
                <div>
                  <div style={{ fontSize: "20px", fontWeight: "600" }}>
                    {" "}
                    {formData?.title}{" "}
                  </div>
                </div>
              </div>
            </Card.Text>
            <hr />

            <Card.Text className="mb-4">
              <div>
                <div>
                  <div style={{ fontSize: "20px" }}> {parse(editorData)} </div>
                </div>
              </div>
            </Card.Text>

            {/* <Card style={{ backgroundColor: "#80808014" }}>
        <Card.Body>
          <Card.Text>
            <div>
              <div>
                <div style={{ fontSize: "16px", fontWeight: "700" }}>
                  {" "}
                  Media Contact
                </div>
              </div>
            </div>
          </Card.Text>
          <Card.Text>
            <div>
              <div>
                <div style={{ fontSize: "16px" }}>
                  <span style={{ fontWeight: "700" }}>
                    Company Name :-
                  </span>
                  <span> {companyObj.name}</span>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div style={{ fontSize: "16px" }}>
                  {" "}
                  <span style={{ fontWeight: "700" }}> Email Id :- </span>
                  <span>{companyObj.email}</span>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div style={{ fontSize: "16px" }}>
                  {" "}
                  <span style={{ fontWeight: "700" }}>
                    Company Website :-
                  </span>
                  <span>{companyObj.website}</span>
                </div>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card> */}
          </div>
        </div>
      )}
    </>
  );
};

export default PressReleaseView;
