import "./UserManage.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";

const DecreasePr = () => {
  const [error, setError] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [directPr, setDirectPr] = useState([]);
  const navigate = useNavigate();
  const { userId } = useParams();

  const [formData, setFormData] = useState({
    userId: "",
    id: "",
    decreasePR: "",
    reason: "",
    packageName: "",
    prLimit: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    alert("hiii");
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/user/decreasepr`, {
        adminToken: token,
        ...formData,
      });
      // console.log("Response from updateInvoivce API:", response.data);
      if (response.data.resCode === "200") {
        showToast("Updated Succesfully!", "success", "success");
        setTimeout(() => {
          navigate(`/admin/user/history/${userId}`);
        }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update Decrease PR details");
      console.error("Error updating  Decrease PR details:", error);
    }
  };

  const fetchDirectPrList = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/user/directPRList`, {
        adminToken: token,
        userId: userId,
      });
      //   console.log("Response from User API:", response.data);
      setDirectPr(response?.data?.message);
      formData.packageName = response?.data?.message[0]?.packageName;
      formData.prLimit = response?.data?.message[0]?.prLimit;
      formData.decreasePR = response?.data?.message[0]?.decreasePR;
      formData.id = response?.data?.message[0]?.id;
      formData.userId = response?.data?.message[0].userId;
    } catch (error) {
      setError("Failed to fetch Direct Pr details");
      console.error("Error fetching Direct Pr details:", error);
    }
  };
  useEffect(() => {
    fetchDirectPrList();
  }, [userId]);

  return (
    <>
      <div className="user_manage_layout">
        <div className="page_heading page_head ">
          <h4 style={{ marginTop: "-30px" }}> Decrease PR </h4>
          <Link to={`/admin/user/history/${userId}`}>
            <span style={{ cursor: "pointer" }}>
              <div style={{ marginTop: "-30px" }} className="backbutton"></div>
            </span>
          </Link>
        </div>

        <div className="add_new_staff" style={{ marginTop: "-20px" }}>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs="12" className="custom_col_add_staff mb-4">
                <Form.Label>Package Name</Form.Label>
                <Form.Control
                  type="text"
                  name="packageName"
                  value={formData.packageName}
                  //   onChange={handleChange}
                  required
                />
                {firstNameError && (
                  <div style={{ color: "red", marginTop: 5 }}>
                    First name must be at least 3 characters long
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="custom_col_add_staff mb-4">
                <Form.Label>PR Limit</Form.Label>
                <Form.Control
                  type="number"
                  name="prLimit"
                  value={formData.prLimit}
                  //   onChange={handleChange}
                  required
                />
              </Col>
              <Col xs="12" className="custom_col_add_staff mb-4">
                <Form.Label>Remaining Credits</Form.Label>
                <Form.Control
                  type="number"
                  name="mobileNumber"
                  // value={formData.mobileNumber}
                  //   onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="custom_col_add_staff mb-4">
                <Form.Label>Decrease PR</Form.Label>
                <Form.Control
                  type="number"
                  name="decreasePR"
                  value={formData.decreasePR}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col xs="12" className="custom_col_add_staff mb-4">
                <Form.Label>Reason</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Decrease PR Reason"
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <button className="add_staff_submit_btn" type="submit">
              Decrease PR
            </button>
          </Form>
          {/* {error && <p className="error_message">{error}</p>} */}
        </div>
      </div>
    </>
  );
};

export default DecreasePr;
