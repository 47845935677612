import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";

const EditorialCardData = [
  // const [cardDetails, setCardDetails] = useState({});

  // useEffect(() => {
  //   const fetchEditorialList = () => {
  //     const token = localStorage.getItem("adminToken");
  //     Instance.post("/siteadmin/pressrelease/list", {
  //       adminToken: token,
  //       userId: "",
  //     })
  //       .then((response) => {
  //         setCardDetails(response.data.stats);
  //         console.log("card details", response.data.stats)
  //       })
  //       .catch((error) => {
  //         console.error("Cannot fetch cards data", error);
  //       });
  //   };
  //   fetchEditorialList();
  // }, []);

  // const cardData = [
  {
    id: 1,
    name: "Editorial Room",
    number: 9037,
    link: "/editorial",
  },
  {
    id: 2,
    name: "Published",
    number: 8919,
    link: "/editorial/publish_press_release",
  },
  {
    id: 3,
    name: "Pending",
    number: 2,
    link: "/editorial/pending_press_release",
  },
  {
    id: 4,
    name: "Draft",
    number: 21,
    link: "/editorial/draft_press_release",
  },
  {
    id: 5,
    name: "Action Required",
    number: 37,
    link: "/editorial/action_press_release",
  },
  {
    id: 6,
    name: "Rejected",
    number: 58,
    link: "/editorial/rejected_press_release",
  },
];

export default EditorialCardData;
