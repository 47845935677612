import React, { useState } from "react";
import "./ManageCountry.css";
import "./ManageCountry.css";
import { Link } from "react-router-dom";
import "../UserManagement/AddUser.css";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useEffect } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const ManageCountry = () => {
  const [tempData, setTempData] = useState([]);
  //const [apiData, setApiData] = useState();
  const [countryList, setCountryList] = useState([]);
  const [accordionOpen, setAccordionOpen] = useState(false);

  const [error, setError] = useState("");
  const [filters, setFilters] = useState({
    // status: "",
    countryName: "",
    countryCode: "",
  });

  const handleSubmit = async (status, id) => {
    // e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/common/countryUpdate`, {
        adminToken: token,
        countryId: id,
        status: status,
      });

      if (response.data.resCode === "200") {
        fetchCountryList();
        showToast(" Status Updated Succesfully!", "success", "success");
        // setTimeout(() => {
        //   // navigate("/country");
        //   window.location.reload();
        // }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update country details");
      console.error("Error updating country details:", error);
    }
  };

  const handleChecked = (event, status, id) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${
        status === "ACTIVE" ? "inactive" : "active"
      } status?`
    );

    if (status === "ACTIVE" && confirmed === true) {
      handleSubmit("INACTIVE", id);
    } else if (
      (status === "INACTIVE" || status === "DEACTIVE" || status === "") &&
      confirmed === true
    ) {
      handleSubmit("ACTIVE", id);
    }

    // else if (status === "DEACTIVE" && confirmed === true) {
    //   handleSubmit("ACTIVE", id);
    // }

    console.log("Switch value:", event.target.checked, status, id);
  };

  const columns = [
    {
      name: "Country code",
      selector: (row) => row.countryCode,
      sortable: true,
    },
    {
      name: "Country Name",
      selector: (row) => row.countryName,
      sortable: true,
    },
    {
      name: "Edit Country",
      cell: (row) => (
        <>
          <Link to={`/admin/country/edit/${row.countryId}`}>
            <img
              src="/build/edit.svg"
              alt="edit"
              width={24}
              height={24}
              priority
            />
          </Link>
        </>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={row?.status === "ACTIVE"}
            onChange={(e) => handleChecked(e, row.status, row.countryId)}
          />
        </Form>
      ),
    },
  ];

  const fetchCountryList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/common/countryList", {
      adminToken: token,
      countryCode: "",
      countryName: "",
      isdCode: "",
      currency: "",
    })
      .then((response) => {
        setCountryList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching country list:", error);
      });
  };
  useEffect(() => {
    fetchCountryList();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/common/countryList", {
      adminToken: token,
      // status: filters.status,
      countryName: filters.countryName,
      countryCode: filters.countryCode,
    })
      .then((response) => {
        setTempData(response?.data?.message);
        setError("");
        console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/common/countryList", {
      adminToken: token,
      // status: filters.status,
      countryName: "",
      countryCode: "",
    })
      .then((response) => {
        setFilters({
          countryName: "",
          countryCode: "",
        });
        setCountryList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(countryList[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...countryList]);
    } else {
      const filteredItems = countryList.filter(
        (item) =>
          (item.countryName &&
            item.countryName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.countryCode &&
            item.countryCode.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };

  return (
    <>
      {/* <div className="user_manage_layout">
        <div className="page_heading page_head ">
          <h4 className="head_1"> Manage Country </h4>
          <div>
            <button className="btn  btn-lg add_btn">Add New Country</button>
          </div>
        </div> */}

      <div className="admin_country_layout">
        <div className="page_heading">
          <h4> Manage Country </h4>
          <Link to="/admin/country/add">
            <button className="btn btn-new"> ADD NEW COUNTRY </button>{" "}
          </Link>
        </div>

        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="country_search ">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-flex">
                <button
                  onClick={(e) => downloadCSV(countryList)}
                  className="btn btn-export mx-3"
                >
                  <img
                    src="/build/img/Icons/export.svg"
                    alt="zex pr wire Logo"
                    width={25}
                    height={25}
                  />
                </button>
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>

            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterUsername"
                      className="country_filter_options"
                    >
                      <Form.Label>Country Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Country Name"
                        name="countryName"
                        value={filters.countryName}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterName"
                      className="country_filter_options"
                    >
                      <Form.Label>Country Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Country Code"
                        name="countryCode"
                        value={filters.countryCode}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={clearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={applyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        {/* 
        <div className="pb-5">
          

          <div>
            <div className="show">
              {" "}
              <span>
                Search :{" "}
                <span>
                  <input type="text" id="search" className="search_btn1"  onChange={filterData}/>
                </span>
              </span>
            </div>
          </div>
        </div> */}

        <form className="form-body row g-3">
          <DataTable
            columns={columns}
            data={tempData}
            // selectableRows
            pagination
            defaultSortFieldId={1}
            responsive={true}
            defaultSortAsc={true}
          ></DataTable>
        </form>
      </div>
    </>
  );
};

export default ManageCountry;
