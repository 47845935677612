import "./PackageLayout.css";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useState } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const SetSequenceCrypto = () => {
  return (
    <>
      <div className="package_pages_layout">
        <div className="package_page_heading">
          <h4> Set sequence Crypto </h4>
          <div className="admin_package_btns">
            <Link to="/admin/crypto_package">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_package_details">
          <Form>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Sequence no</Form.Label>
                <Form.Select name="sequence">
                  <option value="1">1</option>
                  <option value="2">2</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Package Name</Form.Label>
                <Form.Select name="name">
                  <option value="name1">CCW Gold Package</option>
                  <option value="name2">
                    Binance, Coinmarketcap, flipboard, medium, citytelegraph,
                    newsaffinity, newsalarms, with 60+ outlets (crypto + global)
                    - 3500 USD
                  </option>
                </Form.Select>
              </Col>
            </Row>

            <div>
              <Table responsive bordered hover>
                <thead>
                  <tr>
                    <th width="80%">Package Name</th>
                    <th> Sequence No</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> CCW Gold Package</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>
                      Binance, Coinmarketcap, flipboard, medium, citytelegraph,
                      newsaffinity, newsalarms, with 60+ outlets (crypto +
                      global) - 3500 USD
                    </td>
                    <td>0</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <button className="add_package_submit_btn" type="submit">
              Submit
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SetSequenceCrypto;
