import React, { useEffect } from "react";
import "./LoginLog.css";

import { useState } from "react";

import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Instance from "../../Utils/Axios";

const ManageLoginLog = () => {
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [tempData, setTempData] = useState([]);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [logList, setLogList] = useState([]);

  const [filters, setFilters] = useState({
    userName: "",
    email: "",
    loginDate: "",
    ipAddress: "",
  });

  const columns = [
    // {
    //   name: "SI.No.",
    //   selector: (row) => parseInt(row.No),
    //   sortable: true,
    // },
    {
      name: "UserName",
      selector: (row) => row.userName,
      sortable: true,
    },
    {
      name: "email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "IP Address",
      selector: (row) => row.ipAddress,
      sortable: true,
    },
    {
      name: "Date Time",
      selector: (row) => row.loginDate,
      sortable: true,
    },
  ];

  const data = [
    {
      No: "1",
      Name: "admin",
      IP: "2401:4900:1c1b:ef6a:5c5a:c618:3838:f822",
      Date: "10:53 am, Tuesday, 16 July 2024",
    },
    {
      No: "2",
      Name: "admin",
      IP: "110.235.219.140 ",
      Date: "10:32 am, Tuesday, 16 July 2024",
    },
    {
      No: "3",
      Name: "admin",
      IP: "2401:4900:1c1b:ef6a:510a:e1fc:adff:a58b",
      Date: "10:23 am, Monday, 15 July 2024",
    },
  ];

  const fetchLoginLogList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/staff/loginLogs", {
      adminToken: token,
      status: "",
      type: "",
      email: "",
      name: "",
      userName: "",
    })
      .then((response) => {
        setLogList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching Login Log list:", error);
      });
  };
  useEffect(() => {
    fetchLoginLogList();
  }, []);

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...logList]);
    } else {
      const filteredItems = logList.filter(
        (item) =>
          (item.userName &&
            item.userName.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.ipAddress &&
            item.ipAddress.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.loginDate &&
            item.loginDate.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/staff/loginLogs", {
      adminToken: token,
      userName: filters.userName,
      email: filters.email,
      loginDate: filters.loginDate,
      ipAddress: filters.ipAddress,
    })
      .then((response) => {
        setTempData(response?.data?.message);
        setError("");
        console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/staff/loginLogs", {
      adminToken: token,
      // status: filters.status,
      userName: "",
      email: "",
      loginDate: "",
      ipAddress: "",
    })
      .then((response) => {
        setFilters({
          userName: "",
          email: "",
          loginDate: "",
          ipAddress: "",
        });
        setLogList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(logList[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  return (
    <>
      <div className="admin_editorial_layout">
        <div className="page_heading">
          <h4>Manage Login Log </h4>
          <Link to="#">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>

        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="editorial_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <button
                  onClick={(e) => downloadCSV(logList)}
                  className="btn btn-export mx-3"
                >
                  <img
                    src="/build/img/Icons/export.svg"
                    alt="zex pr wire Logo"
                    width={25}
                    height={25}
                  />
                </button>
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterUserName"
                      className="editorial_filter_options"
                    >
                      <Form.Label>UserName</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search UserName"
                        name="userName"
                        value={filters.userName}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterIPAddress"
                      className="editorial_filter_options"
                    >
                      <Form.Label>IP Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search IP Addess"
                        name="ipAddress"
                        value={filters.ipAddress}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterIPAddress"
                      className="editorial_filter_options"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Email"
                        name="email"
                        value={filters.email}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterDateTime"
                      className="editorial_filter_options"
                    >
                      <Form.Label>DateTime</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        id="datetime"
                        placeholder="Search DateTime"
                        name="loginDate"
                        value={filters.loginDate}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={clearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={applyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <DataTable
          columns={columns}
          data={tempData}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
          //  actions={actionsMemo}
        />
      </div>
    </>
  );
};

export default ManageLoginLog;
