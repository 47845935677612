import React from "react";
import "./Editorial.css";
import { Table, Pagination, Dropdown, DropdownButton } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import Instance from "../../Utils/Axios";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import EditorialRoom from "./EditorialRoom";
import EditorialCardData from "./EdtorialCardData";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PressReleaseAll = () => {
  const [error, setError] = useState("");
  const [editorialList, setEditorialList] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [counData, setCountData] = useState([]);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({
    packageId: "",
    title: "",
    updatedOn: "",
    status: "",
    companyName: "",
    packageName: "",
    userName: "",
  });
  const columns = [
    {
      name: "Release ID",
      selector: (row) => parseInt(row.id),
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Publishing Date",
      selector: (row) => row.createdOn,
      sortable: true,
    },
    {
      name: "Package Name",
      selector: (row) => row.packageName,
      sortable: true,
    },
    {
      name: "User",
      selector: (row) => row.userName,
      sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.companyName,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Last Action By",
      selector: (row) => row.Action,
    },
  ];

  const data = [
    {
      ReleaseID: 10460,
      Title: "Badr Jafar awarded Honorary Fe...",
      Date: "2024/07/04 16:11",
      Package: "WL - Yahoo Premium with AP NEWS",
      User: " Etrendystock & Digital",
      Company: "SaharaManagementConsultancy",
      Status: "Pending ",
      Action: "",
    },
  ];

  const fetchEditorialList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/pressrelease/list", {
      adminToken: token,
      // userId: 1,
    })
      .then((response) => {
        setEditorialList(response?.data?.message);
        setTempData(response?.data?.message);
        console.log("this is the state data ", response?.data?.stats);
        const arr = [];
        arr.push(response?.data?.stats?.All);
        arr.push(response?.data?.stats?.PUBLISHED?.Published);
        arr.push(response?.data?.stats?.PENDING?.Pending);
        arr.push(response?.data?.stats?.DRAFT?.Drafts);
        arr.push(response?.data?.stats?.OPEN?.["Action Required"]);
        arr.push(response?.data?.stats?.REJECT?.["Rejected"]);
        // setCountData(...counData, response?.data?.stats?.REPORT?.Reports);

        setCountData(...counData, arr);
        // console.log("open value", counData);

        setError("");
        console.log("check editorial  data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching editorial list:", error);
      });
  };
  useEffect(() => {
    fetchEditorialList();
  }, []);

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...editorialList]);
    } else {
      const filteredItems = editorialList.filter(
        (item) =>
          (item.title &&
            item.title.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.updatedOn &&
            item.updatedOn.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.id &&
            item.id.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.companyName &&
            item.companyName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.packageName &&
            item.packageName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.userName &&
            item.userName.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/pressrelease/list", {
      adminToken: token,
      // status: filters.status,

      id: filters.id,
      title: filters.title,
      updatedOn: filters.updatedOn,
      status: filters.status,
      companyName: filters.companyName,
      packageName: filters.packageName,
      userName: filters.userName,
    })
      .then((response) => {
        setTempData(response?.data?.message);
        setError("");
        console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/pressrelease/list", {
      adminToken: token,
      id: "",
      title: "",
      updatedOn: "",
      status: "",
      companyName: "",
      packageName: "",
      userName: "",
    })
      .then((response) => {
        setFilters({
          id: "",
          title: "",
          updatedOn: "",
          status: "",
          companyName: "",
          packageName: "",
          userName: "",
        });
        setEditorialList(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );

    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(editorialList[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <>
      <div className="admin_editorial_layout">
        <div className="page_heading">
          <h4> All Press Release </h4>
          <Link to="/admin/editorial">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>

        <Row className="my-3">
          {EditorialCardData.map((val, indx) => {
            return (
              <>
                <Col className="col-xl-2 col-lg-4 col-md-4 col-sm-4  col-12  py-2">
                  <Card
                    className="    box_card"
                    // style={{ width: "11rem" }}
                  >
                    <Link to={val.link}>
                      <div className="text-center">
                        <p
                          className="pt-2"
                          style={{ color: "#868e96", fontWeight: "600" }}
                        >
                          {" "}
                          {val.name}
                        </p>
                        <p style={{ color: "#868e96", fontWeight: "600" }}>
                          {" "}
                          {counData[indx]}
                        </p>
                      </div>
                    </Link>
                  </Card>
                </Col>
              </>
            );
          })}

          {/* </div> */}
        </Row>

        <DataTable
          columns={columns}
          data={editorialList}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
          //  actions={actionsMemo}
        />
      </div>
    </>
  );
};

export default PressReleaseAll;
