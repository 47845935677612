import "./AffiliateLayout.css";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

const PaymentPage = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(1);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Requested Amount",
      selector: (row) => row.amount,
      sortable: true,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      selector: (row) =>
        // <Link to={`/affiliate_user/${row.id}`}>
        //   <div className="view_btn">View</div>
        // </Link>
        row.action,
      sortable: true,
    },
  ];
  const columns2 = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Requested Amount",
      selector: (row) => row.amount,
      sortable: true,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      selector: (row) =>
        // <Link to={`/affiliate_user/${row.id}`}>
        //   <div className="view_btn">View</div>
        // </Link>
        row.action,
      sortable: true,
    },
  ];
  const columns3 = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Requested Amount",
      selector: (row) => row.amount,
      sortable: true,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      selector: (row) =>
        // <Link to={`/affiliate_user/${row.id}`}>
        //   <div className="view_btn">View</div>
        // </Link>
        row.action,
      sortable: true,
    },
  ];
  const columns4 = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Requested Amount",
      selector: (row) => row.amount,
      sortable: true,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      selector: (row) =>
        // <Link to={`/affiliate_user/${row.id}`}>
        //   <div className="view_btn">View</div>
        // </Link>
        row.action,
      sortable: true,
    },
  ];

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }
  return (
    <>
      <div className="affiliate_pages_layout">
        <div className="affiliate_page_heading">
          <h4>
            {" "}
            Manage Payments
            <p>Wallet Balance:-$0</p>
          </h4>
        </div>
        <div className="d-flex payment_category" style={{ overflow: "auto" }}>
          <p
            className={`${
              activeCategory === 1 ? "active_category_payment" : ""
            }`}
            onClick={() => {
              setActiveCategory(1);
            }}
          >
            All
          </p>
          <p
            className={`${
              activeCategory === 2 ? "active_category_payment" : ""
            }`}
            onClick={() => {
              setActiveCategory(2);
            }}
          >
            Pending
          </p>
          <p
            className={`${
              activeCategory === 3 ? "active_category_payment" : ""
            }`}
            onClick={() => {
              setActiveCategory(3);
            }}
          >
            Approved
          </p>
          <p
            className={`${
              activeCategory === 4 ? "active_category_payment" : ""
            }`}
            onClick={() => {
              setActiveCategory(4);
            }}
          >
            Denied
          </p>
        </div>
        {activeCategory === 1 ? (
          <>
            <Accordion className="mb-4">
              <Card>
                <Card.Header className="d-md-flex justify-content-between align-items-center">
                  <div className="staff_search">
                    <label>
                      Search:
                      <input
                        type="search"
                        placeholder=""
                        aria-controls="datatable"
                        // onChange={filterData}
                      ></input>
                    </label>
                  </div>
                  <div className="d-md-flex">
                    <CustomToggle as={Button} variant="link" eventKey="0">
                      Filters
                    </CustomToggle>
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Form>
                      <div className="d-flex gap-4 flex-wrap">
                        <Form.Group
                          controlId="filterStatus"
                          className="staff_filter_options"
                        >
                          <Form.Label>Status</Form.Label>
                          <Form.Control
                            as="select"
                            name="status"
                            // value={filters.status}
                            // onChange={handleFilterChange}
                          >
                            <option value="">All</option>
                            <option value="done">Done</option>
                            <option value="pending">Pending</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="filtername"
                          className="staff_filter_options"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Search Name"
                            name="name"
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="filterEmail"
                          className="staff_filter_options"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Search Email"
                            name="email"
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="filterMobile"
                          className="staff_filter_options"
                        >
                          <Form.Label>Amount</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Search Requested Amount"
                            name="amount"
                          ></Form.Control>
                        </Form.Group>
                      </div>
                      <div className="mt-3 d-flex gap-3">
                        <Button variant="secondary" /*onClick={clearFilters}*/>
                          Clear
                        </Button>
                        <Button variant="primary" /*onClick={applyFilters} */>
                          Apply
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <DataTable
              columns={columns}
              //   data={tempData}
              data={[{}]}
              pagination
              defaultSortFieldId={1}
              responsive={true}
              defaultSortAsc={true}
              className="application_datatable"
            />
          </>
        ) : activeCategory === 2 ? (
          <>
            <Accordion className="mb-4">
              <Card>
                <Card.Header className="d-md-flex justify-content-between align-items-center">
                  <div className="staff_search">
                    <label>
                      Search:
                      <input
                        type="search"
                        placeholder=""
                        aria-controls="datatable"
                        // onChange={filterData}
                      ></input>
                    </label>
                  </div>
                  <div className="d-md-flex">
                    <CustomToggle as={Button} variant="link" eventKey="0">
                      Filters
                    </CustomToggle>
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Form>
                      <div className="d-flex gap-4 flex-wrap">
                        <Form.Group
                          controlId="filterStatus"
                          className="staff_filter_options"
                        >
                          <Form.Label>Status</Form.Label>
                          <Form.Control
                            as="select"
                            name="status"
                            // value={filters.status}
                            // onChange={handleFilterChange}
                          >
                            <option value="">All</option>
                            <option value="done">Done</option>
                            <option value="pending">Pending</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="filtername"
                          className="staff_filter_options"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Search Name"
                            name="name"
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="filterEmail"
                          className="staff_filter_options"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Search Email"
                            name="email"
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="filterMobile"
                          className="staff_filter_options"
                        >
                          <Form.Label>Amount</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Search Requested Amount"
                            name="amount"
                          ></Form.Control>
                        </Form.Group>
                      </div>
                      <div className="mt-3 d-flex gap-3">
                        <Button variant="secondary" /*onClick={clearFilters}*/>
                          Clear
                        </Button>
                        <Button variant="primary" /*onClick={applyFilters} */>
                          Apply
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <DataTable
              columns={columns2}
              //   data={tempData}
              data={[{}]}
              pagination
              defaultSortFieldId={1}
              responsive={true}
              defaultSortAsc={true}
              className="application_datatable"
            />
          </>
        ) : activeCategory === 3 ? (
          <>
            <Accordion className="mb-4">
              <Card>
                <Card.Header className="d-md-flex justify-content-between align-items-center">
                  <div className="staff_search">
                    <label>
                      Search:
                      <input
                        type="search"
                        placeholder=""
                        aria-controls="datatable"
                        // onChange={filterData}
                      ></input>
                    </label>
                  </div>
                  <div className="d-md-flex">
                    <CustomToggle as={Button} variant="link" eventKey="0">
                      Filters
                    </CustomToggle>
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Form>
                      <div className="d-flex gap-4 flex-wrap">
                        <Form.Group
                          controlId="filterStatus"
                          className="staff_filter_options"
                        >
                          <Form.Label>Status</Form.Label>
                          <Form.Control
                            as="select"
                            name="status"
                            // value={filters.status}
                            // onChange={handleFilterChange}
                          >
                            <option value="">All</option>
                            <option value="done">Done</option>
                            <option value="pending">Pending</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="filtername"
                          className="staff_filter_options"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Search Name"
                            name="name"
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="filterEmail"
                          className="staff_filter_options"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Search Email"
                            name="email"
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="filterMobile"
                          className="staff_filter_options"
                        >
                          <Form.Label>Amount</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Search Requested Amount"
                            name="amount"
                          ></Form.Control>
                        </Form.Group>
                      </div>
                      <div className="mt-3 d-flex gap-3">
                        <Button variant="secondary" /*onClick={clearFilters}*/>
                          Clear
                        </Button>
                        <Button variant="primary" /*onClick={applyFilters} */>
                          Apply
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <DataTable
              columns={columns3}
              //   data={tempData}
              data={[{}]}
              pagination
              defaultSortFieldId={1}
              responsive={true}
              defaultSortAsc={true}
              className="application_datatable"
            />
          </>
        ) : (
          <>
            <Accordion className="mb-4">
              <Card>
                <Card.Header className="d-md-flex justify-content-between align-items-center">
                  <div className="staff_search">
                    <label>
                      Search:
                      <input
                        type="search"
                        placeholder=""
                        aria-controls="datatable"
                        // onChange={filterData}
                      ></input>
                    </label>
                  </div>
                  <div className="d-md-flex">
                    <CustomToggle as={Button} variant="link" eventKey="0">
                      Filters
                    </CustomToggle>
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Form>
                      <div className="d-flex gap-4 flex-wrap">
                        <Form.Group
                          controlId="filterStatus"
                          className="staff_filter_options"
                        >
                          <Form.Label>Status</Form.Label>
                          <Form.Control
                            as="select"
                            name="status"
                            // value={filters.status}
                            // onChange={handleFilterChange}
                          >
                            <option value="">All</option>
                            <option value="done">Done</option>
                            <option value="pending">Pending</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="filtername"
                          className="staff_filter_options"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Search Name"
                            name="name"
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="filterEmail"
                          className="staff_filter_options"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Search Email"
                            name="email"
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="filterMobile"
                          className="staff_filter_options"
                        >
                          <Form.Label>Amount</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Search Requested Amount"
                            name="amount"
                          ></Form.Control>
                        </Form.Group>
                      </div>
                      <div className="mt-3 d-flex gap-3">
                        <Button variant="secondary" /*onClick={clearFilters}*/>
                          Clear
                        </Button>
                        <Button variant="primary" /*onClick={applyFilters} */>
                          Apply
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <DataTable
              columns={columns4}
              //   data={tempData}
              data={[{}]}
              pagination
              defaultSortFieldId={1}
              responsive={true}
              defaultSortAsc={true}
              className="application_datatable"
            />
          </>
        )}
      </div>
    </>
  );
};

export default PaymentPage;
