import "./VendorLayout.css";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { showToast } from "../../Utils/index";

const VendorPage = () => {
  const [vendorList, setVendorList] = useState([]);
  const [filteredVendor, setFilteredVendor] = useState([]);
  const [error, setError] = useState("");
  const [filters, setFilters] = useState({ status: "" });
  const [accordionOpen, setAccordionOpen] = useState(false);

  useEffect(() => {
    fetchVendorList();
  }, []);

  const fetchVendorList = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/vendor/getList", {
      adminToken: token,
      status: "",
    })
      .then((response) => {
        setVendorList(response.data.message);
        setFilteredVendor(response.data.message);
        setError("");
        console.log("Vendor List:", response.data.message);
      })
      .catch((error) => {
        setError("Failed to fetch vendor data");
        console.error("Error fetching vendor data:", error);
      });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("siteadmin/vendor/getList", {
      adminToken: token,
      status: filters.status,
      name: filters.name,
      url: filters.url,
      credits: filters.credits,
    })
      .then((response) => {
        setFilteredVendor(response.data.message);
        setError("");
        console.log("Filtered template data:", response?.data?.message);
      })
      .catch((error) => {
        setError("failed to apply filters");
        console.error("Error applying filter", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/vendor/getList", {
      adminToken: token,
      status: "",
    })
      .then((response) => {
        setFilters({ status: "", name: "", url: "", credits: "" });
        setFilteredVendor(response.data.message);
        setVendorList(response.data.message);
        setError("");
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("error clearing the filters", error);
      });
  };

  const handleSubmit = async (status, id) => {
    // e.preventDefault();
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/vendor/update`, {
        adminToken: token,
        vendorId: id,
        status: status,
      });
      // console.log("Response from updateCoupon API:", response.data);
      if (response.data.resCode === "200") {
        fetchVendorList();
        showToast(" Status Updated Succesfully!", "success", "success");
        // setTimeout(() => {
        //   // navigate("/category_press_release");
        //   window.location.reload();
        // }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to update vendor details");
      console.error("Error updating vendor details:", error);
    }
  };

  const handleChecked = (event, status, id) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${
        status === "ACTIVE" ? "inactive" : "active"
      } status?`
    );

    if (status === "ACTIVE" && confirmed === true) {
      handleSubmit("INACTIVE", id);
    } else if (
      (status === "INACTIVE" || status === "DEACTIVE" || status === "") &&
      confirmed === true
    ) {
      handleSubmit("ACTIVE", id);
    }

    // else if (status === "DEACTIVE" && confirmed === true) {
    //   handleSubmit("ACTIVE", id);
    // }

    console.log("Switch value:", event.target.checked, status, id);
  };

  const columns = [
    {
      name: "Vendor Name",
      selector: (row) => (
        <a href={`/admin/vendor/history/${row.vendorId}`} target="_blank">
          {" "}
          {row.name}
        </a>
      ),
      sortable: true,
      // width: "200px",
    },
    {
      name: "Number of Publications",
      selector: (row) => row.publicationNumber,
      sortable: true,
      // width: "230px",
    },
    {
      name: "Credits",
      selector: (row) => row.credits,
      sortable: true,
      // width: "120px",
    },
    {
      name: "End point/Feed for Vendor",
      selector: (row) => row.url,
      sortable: true,
      // width: "280px",
    },
    {
      name: "Created On",
      selector: (row) => row.createdOn,
      sortable: true,
    },
    {
      name: "Active/Deactive",
      cell: (row) => (
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={row?.status === "ACTIVE"}
            onChange={(e) => handleChecked(e, row.status, row.vendorId)}
          />
        </Form>
      ),
      // width: "160px",
    },
    {
      name: "Edit",
      cell: (row) => (
        <span className="d-flex gap-1">
          <a href={`/admin/vendor/edit/${row.vendorId}`} target="_blank">
            <img
              src="/build/edit.svg"
              alt="edit"
              width={24}
              height={24}
              priority
            />
          </a>
        </span>
      ),
      // width: "90px",
    },
  ];

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setFilteredVendor([...vendorList]);
    } else {
      const filteredItems = vendorList.filter(
        (item) =>
          (item.name &&
            item.name.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.url &&
            item.url.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.credits &&
            item.credits.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.createdOn &&
            item.createdOn.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.status &&
            item.status.toLowerCase().includes(filterText.toLowerCase()))
      );
      setFilteredVendor([...vendorList]);
    }
  };

  const handleSearch = (e) => {
    let filterText = e.target.value.toLowerCase();
    const filteredItems = vendorList.filter(
      (item) =>
        item.name.toLowerCase().includes(filterText) ||
        item.url.toLowerCase().includes(filterText) ||
        item.credits.toLowerCase().includes(filterText)
    );
    setFilteredVendor(filteredItems);
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );

    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  return (
    <>
      <div className="vendor_pages_layout">
        <div className="vendor_page_heading">
          <h4> Manage Vendor </h4>
          <div className="admin_vendor_btns">
            <div className="search_vendor">
              <input
                placeholder="Search"
                type="search"
                aria-controls="datatable"
                onChange={handleSearch}
              />
              <img
                className={""}
                src="/build/img/search.svg"
                alt="zex pr wire Logo"
                width={24}
                height={24}
                priority
              />
            </div>
            <Link to="/admin/vendor/add">
              <button className="new_vendor_btn"> Create </button>
            </Link>
          </div>
        </div>
        <Accordion className="mb-4">
          <Card>
            <Card.Header>
              <CustomToggle as={Button} variant="link" eventKey="0">
                Filter
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex flex-wrap gap-3">
                    <Form.Group
                      controlId="filterStatus"
                      className="template_filter_options"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filters.status}
                        onChange={handleFilterChange}
                        className=""
                      >
                        <option value="">All</option>
                        <option value="ACTIVE">Active</option>
                        <option value="INACTIVE">Inactive</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterName"
                      className="staff_filter_options"
                    >
                      <Form.Label>Vendor Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Vendor Name"
                        name="name"
                        value={filters.name}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterurl"
                      className="staff_filter_options"
                    >
                      <Form.Label>Vendor URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Vendor URL"
                        name="url"
                        value={filters.url}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filtercredits"
                      className="staff_filter_options"
                    >
                      <Form.Label>Credits</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Search Credits"
                        name="credits"
                        value={filters.credits}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button
                      variant="secondary"
                      className="clear"
                      onClick={clearFilters}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="primary"
                      className="apply"
                      onClick={applyFilters}
                    >
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <DataTable
          columns={columns}
          data={filteredVendor}
          pagination
          responsive={true}
          defaultSortFieldId={1}
          defaultSortAsc={true}
        />
      </div>
    </>
  );
};

export default VendorPage;
