import "./AnalyticsLayout.css";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faDownload } from "@fortawesome/free-solid-svg-icons";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

const Reports = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const formattedDateRange = `${formatDate(
    dateRange[0].startDate
  )} - ${formatDate(dateRange[0].endDate)}`;

  const handleApplyDateRange = () => {
    setShowDatePicker(false);
  };

  const columns = [
    {
      name: "PR Id",
      selector: (row) => row.pr,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.customer,
      sortable: true,
      width: "170px",
    },
    {
      name: "Package Name",
      selector: (row) => row.package,
      sortable: true,
      width: "170px",
    },
    {
      name: "Package",
      selector: (row) => row.package,
      sortable: true,
    },
    {
      name: "PR Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Date of Publication",
      selector: (row) => row.date,
      sortable: true,
      width: "190px",
    },
    {
      name: "Actions",
      selector: (row) => (
        <span>
          <Link to={`/admin/editorial/view_press_release/${row.packageID}`}>
            <img
              src="/build/watch.svg"
              alt="watch"
              width={24}
              height={24}
              className="me-2"
            />
          </Link>
          <FontAwesomeIcon
            icon={faDownload}
            width={24}
            height={24}
            style={{ color: "green", verticalAlign: "-0.3em" }}
          />
        </span>
      ),
      sortable: true,
    },
  ];

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }
  return (
    <>
      <div className="affiliate_pages_layout">
        <div className="affiliate_page_heading">
          <h4> Analytics - Reports</h4>
          <div className="date-picker-container">
            {showDatePicker ? (
              <div className="date-picker-overlay">
                <DateRangePicker
                  ranges={dateRange}
                  onChange={(ranges) => setDateRange([ranges.selection])}
                  onClose={() => setShowDatePicker(false)}
                />
                <button
                  className="apply_date_btn"
                  onClick={handleApplyDateRange}
                >
                  Apply
                </button>
              </div>
            ) : (
              <div
                style={{ textDecoration: "underline" }}
                onClick={() => setShowDatePicker(true)}
              >
                {formattedDateRange}
              </div>
            )}
          </div>
        </div>
        <div className="d-sm-flex gap-4">
          <div className="card_info" style={{ backgroundColor: "#465aff" }}>
            <div className="card_detail">
              <h5>204</h5>
              <span>TOTAL REPORTS</span>
            </div>
            <div className="card_img">
              <span>
                <img src="/build/reports.png" width={36} height={40} />
              </span>
              <p>
                -46.6% <FontAwesomeIcon icon={faCircleInfo} />
              </p>
            </div>
            <img className="card_arrow" src="/build/downarrow.svg" />
          </div>
          <div className="card_info" style={{ backgroundColor: "#ff326e" }}>
            <div className="card_detail">
              <h5>0</h5>
              <span>REPORTS PENDING</span>
            </div>
            <div className="card_img">
              <span>
                <img src="/build/report2.png" width={34} height={38} />
              </span>
              <p>
                0 <FontAwesomeIcon icon={faCircleInfo} />
              </p>
            </div>
            {/* <img className="card_arrow" src="/build/downarrow.svg" /> */}
          </div>
        </div>
        <ul className="mt-5 p-0">
          <li className="report_tab">Submitted</li>
        </ul>
        <Accordion className="mt-5 mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="staff_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    // onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterCustomer"
                      className="staff_filter_options"
                    >
                      <Form.Label>Customer Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Customer Name"
                        name="customer"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterPackage"
                      className="staff_filter_options"
                    >
                      <Form.Label>Package Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Package Name"
                        name="Package"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterTitle"
                      className="staff_filter_options"
                    >
                      <Form.Label>PR Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search PR Title"
                        name="title"
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" /*onClick={clearFilters}*/>
                      Clear
                    </Button>
                    <Button variant="primary" /*onClick={applyFilters} */>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <DataTable
          columns={columns}
          //   data={tempData}
          data={[{}]}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
          className="application_datatable mt-4"
        />
      </div>
    </>
  );
};

export default Reports;
