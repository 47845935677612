import "./TemplateLayout.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";
import DataTable from "react-data-table-component";

const AddTemplate = () => {
  const [templateName, setTemplateName] = useState("");
  const [templateDesc, setTemplateDesc] = useState("");
  const [publicationIds, setPublicationIds] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleAddTemplate = () => {
    if (!templateName.length || !templateDesc) {
      showToast("The Input fields are empty", "error", "error");
      return;
    }
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/template/create", {
      adminToken: token,
      templateName: templateName,
      templateDesc: templateDesc,
      // publicationIds: publicationIds,
    })
      .then((response) => {
        console.log("Template added successfully:", response.data);
        if (response.data.resCode === "200") {
          showToast("Template added successfully", "success", "success");
          setError("");
          setTimeout(() => {
            navigate("/admin/template");
          }, 2000);
        } else {
          showToast("Error adding template", "error", "error");
          setError(response.data.resMessage);
        }
      })
      .catch((error) => {
        console.error("Error adding template:", error);
        alert("Error adding template");
      });
  };

  const columns = [
    {
      name: <input type="checkbox" className="mt-1" />,
      selector: (row) =>
        /*<input type="checkbox" className="mt-1" />*/ row.input,
      sortable: false,
      width: "40px",
    },
    {
      name: "SI.No.",
      selector: (row) => row.id,
      sortable: true,
      width: "90px",
    },
    {
      name: "Publication Name",
      selector: (row) => row.publicationName,
      sortable: true,
      width: "140px",
    },
    {
      name: "Region / Language",
      selector: (row) => row.region,
      sortable: true,
      width: "140px",
    },
    {
      name: "Est. Audience",
      selector: (row) => row.audience,
      sortable: true,
      width: "140px",
    },
    {
      name: "Published URL",
      selector: (row) => row.publishedUrl,
      sortable: true,
      width: "120px",
    },
    {
      name: "Network name",
      selector: (row) => row.networkName,
      sortable: true,
      width: "120px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: false,
      width: "70px",
    },
  ];
  const data = [];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  return (
    <>
      <div className="template_pages_layout">
        <div className="template_page_heading">
          <h4> Templates </h4>
          <div className="admin_template_btns">
            <div className="search_template_add">
              <input placeholder="Search Network / Publication" type="search" />
              <img
                className={""}
                src="/build/img/search.svg"
                alt="zex pr wire Logo"
                width={24}
                height={24}
                priority
              />
            </div>
            <button className="new_template_btn" onClick={handleAddTemplate}>
              Save
            </button>
          </div>
        </div>
        <div className="add_template_details">
          <div className="d-flex justify-content-between flex-wrap flex-column flex-lg-row">
            <div className="add_template_info">
              <p>Template Name</p>
              <input
                type="text"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                required
              />
            </div>
            <div className="add_template_info">
              <p>Template Description</p>
              <input
                type="text"
                value={templateDesc}
                onChange={(e) => setTemplateDesc(e.target.value)}
              />
            </div>
            {/* <div className="add_template_info" >
              <p>Publication IDs</p>
              <input
                type="text"
                value={publicationIds}
                onChange={(e) => setPublicationIds(e.target.value)}
                
              />
            </div> */}
          </div>
          <div className="d-md-flex justify-content-between">
            <div className="template_add_search">
              <label>
                Search:
                <input
                  type="search"
                  placeholder=""
                  aria-controls="datatable"
                  className="search_temp"
                ></input>
              </label>
            </div>
            <div className="new_template_btns">
              <button>
                <img
                  src="/build/up.svg"
                  alt="up"
                  width={14}
                  height={24}
                  className="template_action_btn"
                />
              </button>
              <button>
                <img
                  src="/build/down.svg"
                  alt="down"
                  width={14}
                  height={24}
                  className="template_action_btn"
                />
              </button>
              <button>
                <img
                  src="/build/trashcan.svg"
                  alt="delete"
                  width={14}
                  height={24}
                  className="template_action_btn"
                />
              </button>
            </div>
          </div>
          <div className="admin_template_table">
            <DataTable
              columns={columns}
              data={[{}]}
              defaultSortFieldId={1}
              pagination
              highlightOnHover
              customStyles={customStyles}
            />

            {/* <Table className="mb-0" striped bordered hover>
              <thead className="staff_table_head">
                <tr>
                  <th>
                    <input type="checkbox" className="mt-1" />
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    SI.No.
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Publication Name
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Region / Language
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Est. Audience
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Published URL
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Network name
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Action
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                </tr>
              </thead>
              <tbody className="staff_table_body">
                <tr></tr>
              </tbody>
            </Table> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTemplate;
