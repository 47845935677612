import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import Instance from "../../Utils/Axios";
import { useParams } from "react-router-dom";
import { showToast } from "../../Utils/index";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import axios from "axios";

const TemplateUpdate = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const [templateData, setTemplateData] = useState({
    templateName: "",
    templateDesc: "",
    // publicationIds: "",
    status: "",
  });
  const [publications, setPublications] = useState([]);
  const [error, setError] = useState("");
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    if (templateId) {
      fetchTemplateDetails();
      fetchPublicationList();
    }
  }, [templateId]);

  const fetchTemplateDetails = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/template/details", {
      adminToken: token,
      templateId: templateId,
      status: "",
    })
      .then((response) => {
        // const { message, resCode } = response?.data || {};
        // if (resCode === "200") {
        //   const foundTemplate = message.find(
        //     (template) => template.templateId === templateId
        //   );
        //   if (foundTemplate) {
        // setTemplateData({
        //   templateName: foundTemplate.templateName || "",
        //   templateDesc: foundTemplate.templateDesc || "",
        //   publicationIds: foundTemplate.publicationIds || "",
        //   status: foundTemplate.status || "active",
        // });
        setTemplateData(response?.data);
        setError("");
        console.log("Template Details:", response?.data);
        // } else {
        //   setError("Template not found for the given templateId");
        // }
        // }
        //  else {
        //   setError("Failed to fetch template list");
        // }
      })
      .catch((error) => {
        setError("Failed to fetch template details");
        console.error("Error fetching template details:", error);
      });
  };

  const fetchPublicationList = () => {
    const token = localStorage.getItem("adminToken");
    axios
      .post(
        "https://pradmin.webandapi.com//siteadmin/template/getPublicationList",
        {
          adminToken: token,
          templateId: templateId,
        }
      )
      .then((response) => {
        const { message, resCode } = response?.data || {};
        if (resCode === "200") {
          setPublications(message || []);
          setError("");
          console.log("Publication List:", message);
        } else {
          setError("Failed to fetch publication list");
        }
      })
      .catch((error) => {
        setError("Failed to fetch publication list");
        console.error("Error fetching publication list:", error);
      });
  };

  const handleSave = () => {
    const { templateName, templateDesc, publicationIds, status } = templateData;
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/template/update", {
      adminToken: token,
      templateId: templateId,
      templateName: templateName,
      templateDesc: templateDesc,
      // publicationIds: publicationIds,
      status: status,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          console.log("Template updated successfully:", response.data);
          showToast("Template updated successfully", "success", "success");
          setTimeout(() => {
            navigate("/admin/template");
          }, 2000);
          setError("");
        } else {
          showToast("Error updating template", "error", "error");
          setError(response.data.resMessage || "Error updating template");
        }
      })
      .catch((error) => {
        setError("Error updating template");
        console.error("Error updating template:", error);
      });
  };

  const columns = [
    {
      name: "SI.No.",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "Publication Name",
      selector: (row) => row.publicationName,
    },
    {
      name: "Region / Language",
      selector: (row) => row.region,
    },
    {
      name: "Est. Audience",
      selector: (row) => row.audience,
    },
    {
      name: "Published URL",
      selector: (row) => row.publicationUrl,
    },
    {
      name: "Network name",
      selector: (row) => row.networkName,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <img src="/build/edit.svg" alt="edit" width={18} height={18} />
          <img src="/build/delete.svg" alt="delete" width={18} height={18} />
        </>
      ),
      width: "80px",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };
  const filteredPublications = publications.filter(
    (pub) =>
      pub.publicationName.toLowerCase().includes(filterText.toLowerCase()) ||
      pub.region.toLowerCase().includes(filterText.toLowerCase()) ||
      pub.audience.toLowerCase().includes(filterText.toLowerCase()) ||
      pub.publicationUrl.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <>
      <div className="template_pages_layout">
        <div className="template_page_heading">
          <h4> Edit Template </h4>
          <div className="admin_template_btns">
            <div className="search_template_add">
              <input placeholder="Search Network / Publication" type="search" />
              <img
                className={""}
                src="/build/img/search.svg"
                alt="zex pr wire Logo"
                width={24}
                height={24}
                priority
              />
            </div>
            <button onClick={handleSave} className="new_template_btn">
              {" "}
              Save{" "}
            </button>
          </div>
        </div>
        <div className="add_template_details">
          <div className="d-flex justify-content-between flex-wrap flex-column flex-lg-row">
            <div className="add_template_info">
              <p> Template Name </p>
              <input
                type="text"
                value={templateData.templateName}
                onChange={(e) =>
                  setTemplateData({
                    ...templateData,
                    templateName: e.target.value,
                  })
                }
              />
            </div>
            <div className="add_template_info">
              <p> Template Description </p>
              <input
                type="text"
                value={templateData.templateDesc}
                onChange={(e) =>
                  setTemplateData({
                    ...templateData,
                    templateDesc: e.target.value,
                  })
                }
              />
            </div>
            {/* <div className="add_template_info">
              <p>Publication IDs</p>
              <input
                type="text"
                value={templateData.publicationIds}
                onChange={(e) =>
                  setTemplateData({
                    ...templateData,
                    publicationIds: e.target.value,
                  })
                }
              />
            </div> */}
            <div className="add_template_info">
              <p>Status</p>
              <Form.Control
                as="select"
                name="status"
                value={templateData.status}
                onChange={(e) =>
                  setTemplateData({
                    ...templateData,
                    status: e.target.value,
                  })
                }
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Control>
            </div>
          </div>
          <div className="d-flex gap-5 mb-4">
            <Col xs="1">
              <Form.Group className="">
                <Form.Control as="select" name="status" className="">
                  <option value="">All</option>
                  <option value="Manual">Manual</option>
                  <option value="BNN">BNN</option>
                  <option value="VM">VM</option>
                  <option value="VM_FN">VM_FN</option>
                  <option value="FN">FN</option>
                  <option value="FC">FC</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <button className="new_template_btn">Search Network</button>
          </div>
          <div className="d-md-flex justify-content-between">
            <div className="template_add_search  ">
              <label>
                Search:
                <input
                  type="search"
                  placeholder=""
                  aria-controls="datatable"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  className="search_temp"
                ></input>
              </label>
            </div>
            <div className="new_template_btns">
              <button>
                <img src="/build/up.svg" alt="up" width={10} height={14} />
              </button>
              <button>
                <img src="/build/down.svg" alt="down" width={10} height={14} />
              </button>
              <button>
                <img
                  src="/build/trashcan.svg"
                  alt="delete"
                  width={10}
                  height={14}
                />
              </button>
            </div>
          </div>
          <div className="admin_template_table">
            <DataTable
              columns={columns}
              data={filteredPublications}
              pagination
              highlightOnHover
              pointerOnHover
              customStyles={customStyles}
            />
            {/* <Table className="mb-0" striped bordered hover>
              <thead className="staff_table_head">
                <tr>
                  <th>
                    <input type="checkbox" className="mt-1" />
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    SI.No.
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Publication Name
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Region / Language
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Est. Audience
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Published URL
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Network name
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                  <th>
                    Action
                    <img
                      className="table_sort_img"
                      src="/build/sort.svg"
                      alt="sort"
                      width={10}
                      height={15}
                      priority
                    />
                  </th>
                </tr>
              </thead>
              <tbody className="staff_table_body">
                {publications.map((pub, index) => (
                  <tr key={index}>
                    <td>
                      <input type="checkbox" className="mt-1" />
                    </td>
                    <td>{index + 1}</td>
                    <td>{pub.publicationName}</td>
                    <td>{pub.region}</td>
                    <td>{pub.audience}</td>
                    <td>{pub.publicationUrl}</td>
                    <td>{pub.networkId}</td>
                    <td>
                      <img
                        src="/build/edit.svg"
                        alt="edit"
                        width={18}
                        height={18}
                      />

                      <img
                        src="/build/delete.svg"
                        alt="delete"
                        width={18}
                        height={18}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateUpdate;
