import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState, useEffect } from "react";
import { showToast } from "../../Utils/index";
import Select from "react-select";
import axios from "axios";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Instance from "../../Utils/Axios";
import "./UserPrEdit.css";

const UserPrHistory = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [prHistory, setPrHistory] = useState([]);
  const [tempData, setTempData] = useState([]);
  const { userId } = useParams();
  const [error, setError] = useState("");
  const [userData, setUserData] = useState([]);
  const [filters, setFilters] = useState({
    userId: userId,
    packageName: "",
    increasePR: "",
    totalPR: "",
    price: "",
    createdOn: "",
    originalValidity: "",
    adminAddedValidity: "",
    reason: "",
    userName: "",
  });
  const fetchPackageList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/user/directPRList", {
      adminToken: token,
      userId: userId,
    })
      .then((response) => {
        setPrHistory(response?.data?.message);
        setTempData(response?.data?.message);

        // console.log("check Package  data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the  package data");
        console.error("Error fetching package list:", error);
      });
  };
  useEffect(() => {
    fetchPackageList();
  }, []);

  const fetchUserDetails = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/user/userDetails", {
      adminToken: token,
      userId: userId,
    })
      .then((response) => {
        setUserData(response?.data?.userProfile);

        // console.log("check Package  data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the  User data");
        console.error("Error fetching User Details:", error);
      });
  };
  useEffect(() => {
    fetchUserDetails();
  }, [userId]);

  const columns = [
    {
      name: "Package Name",
      selector: (row) => row.packageName,
      sortable: true,
    },
    {
      name: "Increase PR",
      selector: (row) => row.increasePR,
      sortable: true,
    },
    {
      name: "Total PR",
      selector: (row) => row.totalPR,
      sortable: true,
    },
    {
      name: "PR Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Date & Time",
      selector: (row) => row.createdOn,
      sortable: true,
    },
    {
      name: "Original Validity",
      selector: (row) => row.originalValidity,
      sortable: true,
    },
    {
      name: `Admin Added  Validity`,
      cell: (row) => row.adminAddedValidity,
      width: "100px",
    },
    {
      name: "Reason",
      cell: (row) => row.reason,
      width: "100px",
    },
    {
      name: "Who's Increased PR",
      cell: (row) => row.userName,
      width: "100px",
    },
  ];

  const filterData = (e) => {
    let filterText = e.target.value;
    if (!filterText) {
      setTempData([...prHistory]);
    } else {
      const filteredItems = prHistory.filter(
        (item) =>
          (item.packageName &&
            item.packageName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.increasePR &&
            item.increasePR.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.totalPR &&
            item.totalPR.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.price &&
            item.price.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.createdOn &&
            item.createdOn.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.originalValidity &&
            item.originalValidity
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.adminAddedValidity &&
            item.adminAddedValidity
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.reason &&
            item.reason.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.userName &&
            item.userName.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };
  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/directPRList", {
      adminToken: token,
      userId: userId,
      packageName: filters.packageName,
      increasePR: filters.increasePR,
      totalPR: filters.totalPR,
      price: filters.price,
      createdOn: filters.createdOn,
      originalValidity: filters.originalValidity,
      adminAddedValidity: filters.adminAddedValidity,
      reason: filters.reason,
      userName: filters.userName,
    })
      .then((response) => {
        setTempData(response.data.message);
        setError("");
        console.log("Filtered PR History data:", response?.data?.message);
      })
      .catch((error) => {
        setError("failed to apply filters");
        console.error("Error applying filter", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/user/directPRList", {
      adminToken: token,
      userId: userId,
      packageName: "",
      increasePR: "",
      totalPR: "",
      price: "",
      createdOn: "",
      originalValidity: "",
      adminAddedValidity: "",
      reason: "",
      userName: "",
    })
      .then((response) => {
        setFilters({
          userId: userId,
          packageName: "",
          increasePR: "",
          totalPR: "",
          price: "",
          createdOn: "",
          originalValidity: "",
          adminAddedValidity: "",
          reason: "",
          userName: "",
        });
        setTempData(response.data.message);
        setPrHistory(response.data.message);
        setError("");
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("error clearing the filters", error);
      });
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  return (
    <>
      <div className="userPr_pages_layout">
        <div className="userPr_page_heading">
          <h4 className="">View Increase PR [History] </h4>
          <div className="admin_userPr_btns">
            <Link to="/admin/user" className="">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>

        <div className="my-3 ">
          <div className="py-2">
            {" "}
            <span className="user_data"> User Name :- </span> &nbsp;
            <span className="user_data1">
              {userData?.firstName} {userData?.lastName}{" "}
            </span>
          </div>
          <div className="py-2">
            <span className="user_data">User Email :- </span> &nbsp;
            <span className="user_data1">{userData?.email} </span>
          </div>
        </div>

        <div className="user_data1 text-center pb-5">
          <img
            src="/build/history.png"
            alt="history"
            style={{ height: "22px", width: "22px" }}
          />
          &nbsp; PR History{" "}
        </div>

        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="staff_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterName"
                      className="staff_filter_options"
                    >
                      <Form.Label>Package Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Package Name"
                        name="packageName"
                        value={filters.packageName}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterprice"
                      className="staff_filter_options"
                    >
                      <Form.Label>Increase PR</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Increase PR"
                        name="increasePR"
                        value={filters.increasePR}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterRelease"
                      className="staff_filter_options"
                    >
                      <Form.Label>Total PR</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Total PR"
                        name="totalPR"
                        value={filters.totalPR}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterType"
                      className="staff_filter_options"
                    >
                      <Form.Label>PR Price</Form.Label>
                      <Form.Control
                        type="number"
                        name="price"
                        value={filters.price}
                        onChange={handleFilterChange}
                        placeholder="Search PR Price"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterSubtype"
                      className="staff_filter_options"
                    >
                      <Form.Label>Date & Time</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        id="datetime"
                        name="createdOn"
                        value={filters.createdOn}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterSubtype"
                      className="staff_filter_options"
                    >
                      <Form.Label>Original Validity</Form.Label>
                      <Form.Control
                        type="number"
                        name="originalValidity"
                        value={filters.originalValidity}
                        onChange={handleFilterChange}
                        placeholder="Search Original Validity"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterSubtype"
                      className="staff_filter_options"
                    >
                      <Form.Label>Admin Added Validity</Form.Label>
                      <Form.Control
                        type="number"
                        name="adminAddedValidity"
                        value={filters.adminAddedValidity}
                        onChange={handleFilterChange}
                        placeholder="Search Admin Added Validity"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterSubtype"
                      className="staff_filter_options"
                    >
                      <Form.Label>Reason</Form.Label>
                      <Form.Control
                        type="text"
                        name="reason"
                        value={filters.reason}
                        onChange={handleFilterChange}
                        placeholder="Search Reason "
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterSubtype"
                      className="staff_filter_options"
                    >
                      <Form.Label>Who's Increased PR</Form.Label>
                      <Form.Control
                        type="text"
                        name="userName"
                        value={filters.userName}
                        onChange={handleFilterChange}
                        placeholder="Search Who's Increased PR"
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={clearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={applyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <DataTable
          columns={columns}
          data={tempData}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
        />
      </div>
    </>
  );
};

export default UserPrHistory;
