import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState, useRef, useMemo } from "react";
import Instance from "../../Utils/Axios";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "../../Utils/index";
import JoditEditor from "jodit-react";
import { Placeholder } from "react-bootstrap";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PressReleasePreview from "./PressReleasePreview";
import parse from "html-react-parser";

// import "@ckeditor/ckeditor5-build-classic/build/translations/en";

const PressReleaseAdd = () => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [userList, setUserList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [galleryList, setGalleryList] = useState([]);
  const [galleryString, setGalleryString] = useState("");
  const [id, setId] = useState("");
  const [selectedCompany, setSelectedCompany] = useState({});
  const [company, setCompany] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [categoryString, setCategoryString] = useState("");
  const [count, setCount] = useState(false);
  const [countStatus, setCountStatus] = useState(0);
  const [newCompany, setNewCompany] = useState({
    name: "",
    contactPerson: "",
    address: "",
    mobile: "",
    email: "",
    website: "",
    state: "",
    country: "",
    city: "",
    userId: 1,
  });

  const config = {
    Placeholder: "start typing ..",
  };

  // const options = [
  //   { value: "1", label: "Business" },
  //   { value: "2", label: "Lifestyle" },
  //   { value: "3", label: "Health & Medicine" },
  //   { value: "4", label: "Agriculture" },
  // ];

  const fetchUserList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/user/list", {
      adminToken: token,
      status: "PENDING",
      fromDate: "",
      toDate: "",
      email: "",
      userId: "",
      country: "",
      state: "",
      city: "",
      mobileNumber: "",
      parentId: "",
    })
      .then((response) => {
        // setUserList(response?.data?.message);

        setError("");
        console.log("check user  data", response?.data?.message);
        const userOptions = [];
        // console.log("check Gallery data", response?.data?.message);

        (response?.data?.message).map((val) => {
          userOptions.push({
            value: val.userId,
            label: val.firstName,
          });
        });

        setUserList(userOptions);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching user list:", error);
      });
  };
  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchPackageList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/package/getList", {
      adminToken: token,
      status: "ACTIVE",
    })
      .then((response) => {
        console.log("package id :", response.data.message);
        console.log("package data :", response.data.message?.packageId);
        setPackageList(response?.data?.message);

        setError("");
        // console.log("check Package  data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the  package data");
        console.error("Error fetching package list:", error);
      });
  };
  useEffect(() => {
    fetchPackageList();
  }, []);
  const fetchCompanyList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/user/company/getList", {
      adminToken: token,
      status: "",
    })
      .then((response) => {
        setCompanyList(response?.data?.message);

        setError("");
        // console.log("check Company  data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the  Company data");
        console.error("Error fetching Company list:", error);
      });
  };
  useEffect(() => {
    fetchCompanyList();
  }, []);
  const fetchCategoryList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/category/getList", {
      adminToken: token,
      status: "",
    })
      .then((response) => {
        // setCategoryList(response?.data?.message);

        setError("");
        // console.log("check Company  data", response?.data?.message);
        var option = [];
        response?.data?.message.map((val) => {
          option.push({
            value: val.categoryId,
            label: val.name,
          });
        });

        setCategoryList(option);
      })

      .catch((error) => {
        setError("Failed to fetch the Category data");
        console.error("Error fetching Category list:", error);
      });
  };
  useEffect(() => {
    fetchCategoryList();
  }, []);
  const fetchCountryList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/common/countryList", {
      adminToken: token,
      status: "",
    })
      .then((response) => {
        setCountryList(response?.data?.message);

        setError("");
        // console.log("check Company  data", response?.data?.message);
      })

      .catch((error) => {
        setError("Failed to fetch the Category data");
        console.error("Error fetching Category list:", error);
      });
  };
  useEffect(() => {
    fetchCountryList();
  }, []);

  const fetchGalleryList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/gallery/getList", {
      adminToken: token,
    })
      .then((response) => {
        // setGalleryList(response?.data?.message);

        setError("");
        const options = [];
        // console.log("check Gallery data", response?.data?.message);

        (response?.data?.message).map((val) => {
          options.push({
            value: val.galleryId,
            label: val.name,
          });
        });

        setGalleryList(options);
      })

      .catch((error) => {
        setError("Failed to fetch the Gallery data");
        console.error("Error fetching Gallery list:", error);
      });
  };
  useEffect(() => {
    fetchGalleryList();
  }, []);
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    userId: "",
    packageId: "",
    title: "",
    description: "",
    showContactDetails: "",
    addNote:
      "Test PRTest PRTest PRTest PRTest PRTest PRTest PRTest PRTest PRTest PRTest PR",
    image: "",
    status: "OPEN",
    categoryId: 2,
    companyId: "",
    action_date: "",
    company: {
      name: "",
      contactPerson: "",
      address: "",
      mobile: "",
      email: "",
      website: "",
      state: "",
      country: "",
      city: "",
      userId: 1,
    },
  });

  const handleSelectChange = (e) => {
    const selectedId = e.target.value;
    setId(e.target.value);
    console.log("company id is : ", e.target.value);

    const companyobj = companyList.find((obj) => obj.companyId === selectedId);
    setSelectedCompany(companyobj);
    // setCompany(company);
    console.log(" selected company is : ", selectedCompany);
    setCompany({
      name: selectedCompany.name,
      contactPerson: selectedCompany.contactPerson,
      address: selectedCompany.address,
      mobile: selectedCompany.mobile,
      email: selectedCompany.email,
      website: selectedCompany.website,
      state: selectedCompany.state,
      country: selectedCompany.country,
      city: selectedCompany.city,
      userId: 1,
    });
  };

  // const [company, setCompany] = useState({});

  const companyHandleChange = (e) => {
    const { name, value } = e.target;
    setCompany((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNewCompany = (e) => {
    const { name, value } = e.target;
    setNewCompany((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const addNewPress = (e) => {
    formData.userId = selectedUser.value;
    formData.description = editorData;
    formData.company = newCompany;
    formData.image = galleryString;
    formData.categoryId = categoryString;

    if (countStatus === 1) {
      formData.status = "PUBLISHED";
    } else if (countStatus === 2) {
      formData.status = "PENDING";
    } else {
      formData.status = "OPEN";
    }

    // if (companyId === "") {
    //   setFormData(...formData, {});
    // }

    console.log("add data is : ", formData);

    alert("hii");

    e.preventDefault();
    const token = localStorage.getItem("adminToken");

    Instance.post("/siteadmin/pressrelease/create", {
      adminToken: token,
      ...formData,
    })
      .then((response) => {
        if (response.data.resCode === "100") {
          showToast("Already a member", "error", "error");
          setError(response.data.resMessage);
          console.log("User added failed:", response.data);
        } else {
          console.log("User added successfully:", response.data);
          showToast("User added successfully", "success", "success");
          setError("");
          setTimeout(() => {
            navigate("/admin/editorial");
          }, 2000);
        }
      })
      .catch((error) => {
        showToast("Error", "error", "error");
        setError("Failed to add press Release");
        console.error("Error adding Press Release:", error);
      });
  };

  const [editorData, setEditorData] = useState("");

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    // console.log({ event, editor, data });
    // console.log(data);
  };

  const handleMultiSelect = (obj) => {
    const tempArr = obj.map((ar) => ar.value);
    var str = "";
    tempArr.map((val, indx) => {
      if (indx <= tempArr.length - 2) {
        str = str + val + "|";
      }
      if (indx == tempArr.length - 1) {
        str = str + val;
      }
    });

    setCategoryString(str);
    // console.log("------ctaegory string  is ===========", str);
  };

  const handleMultiImage = (obj) => {
    const tempArr = obj.map((ar) => ar.value);
    var str = "";
    tempArr.map((val, indx) => {
      if (indx <= tempArr.length - 2) {
        str = str + val + "|";
      }
      if (indx == tempArr.length - 1) {
        str = str + val;
      }
    });

    setGalleryString(str);
    // console.log("------ctaegory string  is ===========", str);
  };

  const handlePreview = (e) => {
    e.preventDefault();
    console.log("aaaaaaaaaa", formData);
    alert("hiiiiii");

    <PressReleasePreview formData={formData} />;
  };

  const handlePreviewClick = () => {
    navigate("/admin/editorial/preview_press_release", {
      state: { data: formData }, // Pass your data here
    });
  };

  // console.log("=================description========", editorData);
  // let ans = parse(editorData);
  // console.log(ans);

  return (
    <>
      {count === false ? (
        <div className="admin_editorial_layout">
          <div className="page_heading">
            <h4>Create Press Release</h4>
            <Link to="/admin/editorial">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
          <div className="add_new_editorial">
            <Form onSubmit={addNewPress}>
              <Row>
                <Col className="col-12 custom_col_add_editorial mb-4">
                  <Form.Label>Select User</Form.Label>
                  {/* <Form.Select
                  // as="select"
                  name="userId"
                  value={formData.userId}
                  onChange={handleChange}
                >
                  <option value="">Select User</option>
                  {userList.map((val) => (
                    <option key={val.userId} value={val.userId}>
                      {val.firstName}
                    </option>
                  ))}
                </Form.Select> */}
                  <Select
                    isSearchable
                    defaultValue={selectedUser}
                    onChange={setSelectedUser}
                    options={userList}
                  />
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Title of the release</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Press Release body</Form.Label>
                  {/* <JoditEditor
                  ref={editor}
                  value={content}
                  name="description"
                  //value={formData.description}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  //onChange={handleChange}
                  style={{ height: "500px" }}
                  className="jodit-wysiwyg"

                  // onChange={(e)=>console.log(e)}
                /> */}

                  {/* ============ckeditor   ================ */}
                  <div className="CKEditorComponent">
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorData}
                      onChange={handleEditorChange}
                    />
                    {/* <div>
                    <h3>Content</h3>
                    <div dangerouslySetInnerHTML={{ __html: editorData }} />
                  </div> */}
                  </div>

                  {/* ============end ckeditor================== */}
                </Col>
              </Row>
              <Row>
                <Col className="col-12 custom_col_add_editorial mb-4">
                  <Form.Label>Insert Image & Copy Image URL</Form.Label>
                  {/* <Form.Select
                  // as="select"
                  name="image"
                  value={formData.image}
                  onChange={handleChange}


                  required
                >
                  {galleryList.map((val) => {
                    return (
                      <>
                        <option value={val.name}>{val.name}</option>
                      </>
                    );
                  })}
                </Form.Select> */}
                  <Select
                    isSearchable
                    defaultValue={selectedOption}
                    //  onChange={setSelectedOption}
                    onChange={(e) => handleMultiImage(e)}
                    options={galleryList}
                    isMulti
                  />
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Package</Form.Label>
                  <Form.Control
                    as="select"
                    name="packageId"
                    value={formData.packageId}
                    onChange={handleChange}
                  >
                    <option value="">Select Package</option>
                    {packageList.map((val) => (
                      <option value={val.packageId}>{val.name}</option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="custom_col_add_editorial mb-4">
                  <Form.Label>Select Company</Form.Label>
                  <Form.Control
                    as="select"
                    name="companyId"
                    value={formData.companyId}
                    // onChange={handleSelectChange}
                    onChange={handleChange}
                  >
                    <option value="">Select Company</option>
                    {companyList.map((val) => {
                      return (
                        <>
                          <option value={val.companyId}>{val.name}</option>
                        </>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Row>
              <Row
                className="text-center pt-2 "
                style={{ color: "#2e61f2", fontWeight: "700" }}
              >
                <Col>OR</Col>
              </Row>
              <Row className="pb-2" style={{ fontSize: "40px" }}>
                <Col>
                  <Form.Label>Sender Details / Company Details </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Contact Person Name :-</Form.Label>
                  <Form.Control
                    type="text"
                    name="contactPerson"
                    // value={company.contactPerson}
                    // onChange={(e) => companyHandleChange(e)}
                    // placeholder="john Smith"

                    value={newCompany.contactPerson}
                    onChange={handleNewCompany}
                    required={formData.companyId === ""}
                    disabled={formData.companyId != ""}
                  ></Form.Control>
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Company Name :-</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    // value={company.name}
                    // onChange={(e) => companyHandleChange(e)}
                    // placeholder="ZXPREWIRE*"

                    value={newCompany.name}
                    onChange={handleNewCompany}
                    required={formData.companyId === ""}
                    disabled={formData.companyId != ""}
                  ></Form.Control>
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Email :-</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    // value={company.email}
                    // onChange={(e) => companyHandleChange(e)}
                    // placeholder="info@zexprwire.com*"

                    value={newCompany.email}
                    onChange={handleNewCompany}
                    required={formData.companyId === ""}
                    disabled={formData.companyId != ""}
                  ></Form.Control>
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Phone :-</Form.Label>
                  <Form.Control
                    type="number"
                    name="mobile"
                    // value={company.mobile}
                    // onChange={(e) => companyHandleChange(e)}
                    // placeholder="1-545-XXXXX"
                    value={newCompany.mobile}
                    onChange={handleNewCompany}
                    required={formData.companyId === ""}
                    disabled={formData.companyId != ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Company Website :-</Form.Label>
                  <Form.Control
                    type="text"
                    name="website"
                    // value={company.website}
                    // onChange={(e) => companyHandleChange(e)}
                    // placeholder="http://www.google.com"
                    value={newCompany.website}
                    onChange={handleNewCompany}
                    required={formData.companyId === ""}
                    disabled={formData.companyId != ""}
                  ></Form.Control>
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Company Address :-</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    // value={company.address}
                    // onChange={(e) => companyHandleChange(e)}
                    // placeholder="1A-LANE.."

                    value={newCompany.address}
                    onChange={handleNewCompany}
                    required={formData.companyId === ""}
                    disabled={formData.companyId != ""}
                  ></Form.Control>
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>City & State (Both mandate) :-</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    //  value={company.city}
                    // onChange={(e) => companyHandleChange(e)}
                    // placeholder="California*"

                    value={newCompany.city}
                    onChange={handleNewCompany}
                    required={formData.companyId === ""}
                    disabled={formData.companyId != ""}
                  ></Form.Control>
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    // name="country"
                    // value={company.country}
                    // onChange={(e) => companyHandleChange(e)}
                    name="country"
                    value={newCompany.country}
                    onChange={handleNewCompany}
                    required={formData.companyId === ""}
                    disabled={formData.companyId != ""}
                  >
                    <option value="">Select Country</option>
                    {countryList.map((val) => (
                      <option value={val.countryName}>{val.countryName}</option>
                    ))}

                    {/* <option value=""> </option>
                  <option value="India">India</option>
                  <option value="USA">USA</option>
                  <option value="Paris">Paris</option>
                  <option value="London">London</option>
                  <option value="UK">UK</option> */}
                  </Form.Control>
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>Select Categories</Form.Label>
                  <Select
                    options={categoryList}
                    onChange={(cl) => handleMultiSelect(cl)}
                    placeholder="Select Distribution Options"
                    isMulti
                    closeMenuOnSelect={false}
                    required
                  />
                </Col>
                <Col className="custom_col_add_editorial mb-4">
                  <Form.Label>
                    Do you want to show contact details on Press Release?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="showContactDetails"
                    value={formData.showContactDetails}
                    onChange={handleChange}
                    required
                  >
                    <option value="Do you want to show contact details on Press Release?">
                      {" "}
                      Do you want to show contact details on Press Release?
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Control>
                  <p
                    className="py-2"
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "green",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    [ This means if you select "NO" then your contact details
                    will not be visible in our newsroom, but it will be
                    syndicated and visible on other news Platforms ]
                  </p>
                </Col>
              </Row>

              <Row className="pb-5 text-center">
                <Col className=" ">
                  <button
                    className="add_editorial_submit_btn"
                    type="submit"
                    onClick={() => setCountStatus(1)}
                    style={{ width: "200px" }}
                  >
                    Publish
                  </button>
                </Col>
                <Col className=" ">
                  <button
                    className="add_editorial_submit_btn"
                    type="submit"
                    onClick={() => setCountStatus(2)}
                    style={{ width: "200px" }}
                  >
                    Save Pending
                  </button>
                </Col>
                <Col className=" ">
                  <button
                    className="add_editorial_submit_btn"
                    type="button"
                    style={{ width: "200px" }}
                    // onClick={handlePreviewClick}
                    onClick={() => setCount(!count)}
                  >
                    Preview
                  </button>
                </Col>
              </Row>
            </Form>

            {/* {error && <p className="error_message">{error}</p>} */}
          </div>
        </div>
      ) : (
        <div className="admin_editorial_layout">
          <div className="page_heading">
            <h4>Preview Press Release</h4>
            <Link onClick={() => setCount(!count)}>
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
          {/* formData.userId = selectedUser.value; formData.description =
          editorData; formData.company = company; formData.image =
          selectedOption.value; formData.categoryId = categoryString; */}
          <div className="add_new_editorial">
            <Card.Text className="mb-4">
              <div>
                <div>
                  <div style={{ fontSize: "20px", fontWeight: "600" }}>
                    {" "}
                    {formData?.title}{" "}
                  </div>
                </div>
              </div>
            </Card.Text>
            <hr />

            <Card.Text className="mb-4">
              <div>
                <div>
                  <div style={{ fontSize: "20px" }}> {parse(editorData)} </div>
                </div>
              </div>
            </Card.Text>

            <Card style={{ backgroundColor: "#80808014" }}>
              <Card.Body>
                <Card.Text>
                  <div>
                    <div>
                      <div style={{ fontSize: "16px", fontWeight: "700" }}>
                        {" "}
                        Media Contact
                      </div>
                    </div>
                  </div>
                </Card.Text>
                <Card.Text>
                  <div>
                    <div>
                      <div style={{ fontSize: "16px" }}>
                        <span style={{ fontWeight: "700" }}>
                          Company Name :-
                        </span>
                        <span> {company.name}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div style={{ fontSize: "16px" }}>
                        {" "}
                        <span style={{ fontWeight: "700" }}> Email Id :- </span>
                        <span>{company.email}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div style={{ fontSize: "16px" }}>
                        {" "}
                        <span style={{ fontWeight: "700" }}>
                          Company Website :-
                        </span>
                        <span>{company.website}</span>
                      </div>
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default PressReleaseAdd;
