import "./PackageLayout.css";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useState } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const SetSequence = () => {
  return (
    <>
      <div className="package_pages_layout">
        <div className="package_page_heading">
          <h4 className="addpack"> Set sequence normal </h4>
          <div className="admin_package_btns">
            <Link to="/admin/package">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_package_details">
          <Form>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Sequence no</Form.Label>
                <Form.Select name="sequence">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Package Name</Form.Label>
                <Form.Select name="name">
                  <option value="name1">
                    Reputed News Publications Total 100+ Links
                  </option>
                  <option value="name2">
                    Google News + International & Local Publications Total 350+
                    Links
                  </option>
                  <option value="name3">
                    Google News Syndication Total 200+ Links
                  </option>
                  <option value="name4">
                    Etrendystock PR + Google News Syndication with 350 Links
                  </option>
                </Form.Select>
              </Col>
            </Row>

            <div>
              <Table responsive bordered hover>
                <thead>
                  <tr>
                    <th width="80%">Package Name</th>
                    <th> Sequence No</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Reputed News Publications Total 100+ Links</td>
                    <td>19</td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      Google News + International & Local Publications Total
                      350+ Links
                    </td>
                    <td>20</td>
                  </tr>
                  <tr>
                    <td> Google News Syndication Total 200+ Links</td>
                    <td>20</td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      Etrendystock PR + Google News Syndication with 350 Links
                    </td>
                    <td>12</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <button className="add_package_submit_btn" type="submit">
              Submit
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SetSequence;
