import "./PaymentLayout.css";
import { Link, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import Instance from "../../Utils/Axios";

const PaymentDetail = () => {
  const { id } = useParams();
  console.log("id is ", id);
  const [error, setError] = useState("");
  const [paymentHistory, setPaymentHistory] = useState([]);

  const columns = [
    {
      name: "user",
      selector: (row) => row.user,
      sortable: true,
      width: "115px",
    },
    {
      name: "Package Name",
      selector: (row) => row.packageName,
      sortable: true,
      width: "130px",
    },
    {
      name: "Original Price",
      selector: (row) => row.amount,
      sortable: true,
      width: "120px",
    },
    {
      name: "Paid USD",
      selector: (row) => row.amount,
      sortable: true,
      width: "100px",
    },
    {
      name: "Coupon Name",
      selector: (row) => row.couponCode,
      sortable: true,
      width: "130px",
    },
    {
      name: "Reason",
      selector: (row) => row.remarks,
      sortable: true,
      width: "130px",
    },
    {
      name: "How many get discount .% OR FLAT",
      selector: (row) => row.discountValue,
      sortable: true,
      width: "130px",
    },
    {
      name: "Payment Method",
      selector: (row) => row.paymentMethod,
      sortable: true,
      width: "130px",
    },
    {
      name: "Network",
      selector: (row) => row.network,
      sortable: true,
      width: "100px",
    },
    {
      name: "Charge Code",
      selector: (row) => row.charge,
      sortable: true,
      width: "120px",
    },
  ];

  const fetchPaymentDetails = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await Instance.post(`/siteadmin/payments/history`, {
        adminToken: token,
        id: id,
      });
      console.log("Response from Coupon API:", response?.data?.message);
      setPaymentHistory(response?.data?.message);

      setError("");
    } catch (error) {
      setError("Failed to fetch payment details");
      console.error("Error fetching payment details for edit:", error);
    }
  };
  useEffect(() => {
    fetchPaymentDetails();
  }, [id]);
  return (
    <>
      <div className="payments_pages_layout">
        <div className="payments_history_page_heading">
          <h4> View Payment History </h4>

          <div className="admin_payment_btns">
            <Link to="/admin/payments_history">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="payment_history_table">
          <h4>Payment History</h4>
          <DataTable
            columns={columns}
            data={paymentHistory}
            responsive={true}
            defaultSortFieldId={1}
          />
        </div>
      </div>
    </>
  );
};

export default PaymentDetail;
