import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./ManageInvoice.css";

import { Accordion, Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Instance from "../../Utils/Axios";

const ManageInvoice = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [error, setError] = useState("");
  const [invoiceList, setInvoiceList] = useState([]);
  const [tempData, setTempData] = useState([]);
  const { id } = useParams();
  const [filters, setFilters] = useState({
    email: "",
    name: "",
    address: "",
    gst: "",
    id: "",
  });

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "GST",
      selector: (row) => row.gst,
      sortable: true,
    },

    {
      name: "Actions",
      //  selector: row => row.mobile,
      cell: (row) => (
        <>
          <a href={`/admin/manage_invoice_company/edit/${row.id}`}>
            <img
              src="/build/edit.svg"
              alt="edit"
              width={24}
              height={24}
              priority
              className="me-2"
            />
          </a>
        </>
      ),
    },
  ];

  const fetchInvoiceList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/invoice/detail", {
      adminToken: token,
      status: "",
    })
      .then((response) => {
        setInvoiceList([response?.data?.message]);
        setTempData([response?.data?.message]);
        setError("");
        // console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching Invoice list:", error);
      });
  };
  useEffect(() => {
    fetchInvoiceList();
  }, []);

  const filterData = (e) => {
    let filterText = e.target.value;
    // console.log(filterText);

    if (!filterText) {
      setTempData([...invoiceList]);
    } else {
      const filteredItems = invoiceList.filter(
        (item) =>
          (item.name &&
            item.name.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.id &&
            item.id.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.address &&
            item.address.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.gst &&
            item.gst.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/invoice/detail", {
      adminToken: token,

      email: filters.email,
      name: filters.name,
      address: filters.address,
      gst: filters.gst,
      id: filters.id,
    })
      .then((response) => {
        setTempData([response?.data?.message]);
        setError("");
        console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/invoice/detail", {
      adminToken: token,
      // status: filters.status,
      email: "",
      name: "",
      address: "",
      gst: "",
      id: "",
    })
      .then((response) => {
        setFilters({
          email: "",
          name: "",
          address: "",
          gst: "",
          id: "",
        });
        setInvoiceList([response?.data?.message]);
        setTempData([response?.data?.message]);
        setError("");
        console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );

    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(invoiceList[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  return (
    <>
      <div className="admin_invoice_layout">
        <div className="page_heading">
          <h4>Invoice Management </h4>

          <Link to="#">
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        {/* 
        <Accordion className="my-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="invoice_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <button
                  onClick={(e) => downloadCSV(invoiceList)}
                  className="btn btn-export mx-3"
                >
                  <img
                    src="/build/img/Icons/export.svg"
                    alt="zex pr wire Logo"
                    width={25}
                    height={25}
                  />
                </button>
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterUsername"
                      className="invoice_filter_options"
                    >
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Name"
                        name="name"
                        value={filters.name}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterUsername"
                      className="invoice_filter_options"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Search Email"
                        name="email"
                        value={filters.email}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterAddress"
                      className="invoice_filter_options"
                    >
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Address"
                        name="address"
                        value={filters.address}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterEmail"
                      className="invoice_filter_options"
                    >
                      <Form.Label> GST</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search GST"
                        name="gst"
                        value={filters.gst}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterSINo"
                      className="invoice_filter_options"
                    >
                      <Form.Label>Id</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search SI.No"
                        name="id"
                        value={filters.id}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={clearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={applyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion> */}

        <DataTable
          columns={columns}
          data={tempData}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
        />
      </div>
    </>
  );
};

export default ManageInvoice;
