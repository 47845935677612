import "./PackageLayout.css";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useState } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

const SetSequenceIndian = () => {
  return (
    <>
      <div className="package_pages_layout">
        <div className="package_page_heading">
          <h4> Set sequence Indian PR </h4>
          <div className="admin_package_btns">
            <Link to="/admin/crypto_package">
              <span style={{ cursor: "pointer" }}>
                <div className="backbutton"></div>
              </span>
            </Link>
          </div>
        </div>
        <div className="add_package_details">
          <Form>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Sequence no</Form.Label>
                <Form.Select name="sequence">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_add_staff mb-4">
                <Form.Label>Package Name</Form.Label>
                <Form.Select name="name">
                  <option value="name1">
                    ANI News with DJ, Benzinga and 350+ News Publications
                  </option>
                  <option value="name2">EDTIMES</option>
                  <option value="name3">ANI PTI and Business Standard</option>
                  <option value="name4">
                    Press Trust of India (PTI) with 30+ News Publications
                  </option>
                </Form.Select>
              </Col>
            </Row>

            <div>
              <Table responsive bordered hover>
                <thead>
                  <tr>
                    <th width="80%">Package Name</th>
                    <th> Sequence No</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      ANI News with DJ, Benzinga and 350+ News Publications
                    </td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>EDTIMES</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>ANI PTI and Business Standard</td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td>
                      Press Trust of India (PTI) with 30+ News Publications
                    </td>
                    <td>3</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <button className="add_package_submit_btn" type="submit">
              Submit
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SetSequenceIndian;
