import "./PaymentLayout.css";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { LightningFill } from "react-bootstrap-icons";
import Instance from "../../Utils/Axios";

const PaymentHistoryPage = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [error, setError] = useState([]);
  const [filters, setFilters] = useState({
    paymentMethod: "",
    user: "",
    packageName: "",
    amount: "",
    createdOn: "",
    discountValue: "",
    status: "",
    id: "",
  });

  const fetchPaymentList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/payments/history", {
      adminToken: token,
    })
      .then((response) => {
        setPaymentData(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching Payment list:", error);
      });
  };
  useEffect(() => {
    fetchPaymentList();
  }, []);

  const columns = [
    {
      name: "ID",
      selector: (row) => parseInt(row.id),
      sortable: true,
    },
    {
      name: "PaymIent Method",
      selector: (row) => (
        <a href={`/admin/payments_history/${row.id}`} target="_blank">
          {" "}
          {row.paymentMethod}{" "}
        </a>
      ),
      sortable: true,
    },
    {
      name: "User",
      selector: (row) => row.user,
      sortable: true,
    },
    {
      name: "Package",
      selector: (row) => row.packageName,
      sortable: true,
    },
    {
      name: "USD",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "DateTime",
      selector: (row) => row.createdOn,
      sortable: true,
    },
    {
      name: "Discount",
      selector: (row) => row.discountValue,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Invoice",
      cell: (row) => (
        <>
          {row.paymentMode === "ONL" ? (
            <a href={`#`}>
              <img
                src="/build/download_icon.png"
                alt="download"
                width={24}
                height={24}
                priority
                className="me-2   "
              />
            </a>
          ) : null}
        </>
        // <div>
        //   {row.status === "download" ? (
        //     <img
        //       src="/build/img/Icons/export.svg"
        //       alt="zex pr wire Logo"
        //       width={16}
        //       height={16}
        //     />
        //   ) : (
        //     ""
        //   )}
        //   <Link to="/admin/payments/add_user">
        //     <button className="payment_add_btn">Add User</button>
        //   </Link>
        // </div>
      ),
    },
  ];

  const filterData = (e) => {
    let filterText = e.target.value;
    // console.log(filterText);

    if (!filterText) {
      setTempData([...paymentData]);
    } else {
      const filteredItems = paymentData.filter(
        (item) =>
          (item.id &&
            item.id.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.paymentMethod &&
            item.paymentMethod
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.user &&
            item.user.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.packageName &&
            item.packageName
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.amount &&
            item.amount.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.createdOn &&
            item.createdOn.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.discountValue &&
            item.discountValue.toLowerCase().includes(filterText.toLowerCase()))
      );
      setTempData([...filteredItems]);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/payments/history", {
      adminToken: token,

      paymentMethod: filters.paymentMethod,
      user: filters.user,
      packageName: filters.packageName,
      amount: filters.amount,
      createdOn: filters.createdOn,
      discountValue: filters.discountValue,
      status: filters.status,
      id: filters.id,
    })
      .then((response) => {
        setTempData(response?.data?.message);
        setError("");
        console.log("Filtered data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to apply filters");
        console.error("Error applying filters:", error);
      });
  };

  const clearFilters = () => {
    const token = localStorage.getItem("adminToken");
    Instance.post("/siteadmin/payments/history", {
      adminToken: token,
      paymentMethod: "",
      user: "",
      packageName: "",
      amount: "",
      createdOn: "",
      discountValue: "",
      status: "",
      id: "",
    })
      .then((response) => {
        setFilters({
          paymentMethod: "",
          user: "",
          packageName: "",
          amount: "",
          createdOn: "",
          discountValue: "",
          status: "",
          id: "",
        });
        setPaymentData(response?.data?.message);
        setTempData(response?.data?.message);
        setError("");
        console.log("Cleared filters. Updated data:", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to clear filters");
        console.error("Error clearing filters:", error);
      });
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );

    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(paymentData[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  return (
    <>
      <div className="payments_pages_layout">
        <div className="payments_page_heading">
          <h4> Manage Payment History </h4>
        </div>
        <div
          className="payment_export_btn"
          onClick={(e) => downloadCSV(paymentData)}
        >
          <img
            src="/build/download.svg"
            alt="zex pr wire Logo"
            width={20}
            height={20}
            className="payment_export_img"
          />
        </div>
        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="staff_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filtermethod"
                      className="staff_filter_options"
                    >
                      <Form.Label>Payment Method</Form.Label>
                      <Form.Control
                        as="select"
                        name="method"
                        // value={filters.status}
                        // onChange={handleFilterChange}
                      >
                        <option value="">All</option>
                        <option value="offline">Offline</option>
                        <option value="paypal">Paypal</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterpaymentId"
                      className="staff_filter_options"
                    >
                      <Form.Label>Payment Id</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Payment Id"
                        name="id"
                        value={filters.id}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterUser"
                      className="staff_filter_options"
                    >
                      <Form.Label>User</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search User"
                        name="user"
                        value={filters.user}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterPackage"
                      className="staff_filter_options"
                    >
                      <Form.Label>Package </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Package "
                        name="packageName"
                        value={filters.packageName}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterPackage"
                      className="staff_filter_options"
                    >
                      <Form.Label>USD</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Search USD "
                        name="amount"
                        value={filters.amount}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterPackage"
                      className="staff_filter_options"
                    >
                      <Form.Label>Discount</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search discount "
                        name="discountValue"
                        value={filters.discountValue}
                        onChange={handleFilterChange}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filterPackage"
                      className="staff_filter_options"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="Search discount "
                        name="status"
                        value={filters.status}
                        onChange={handleFilterChange}
                      >
                        <option value="">All </option>
                        <option value="APPROVED">APPROVED </option>
                        <option value="PENDING">PENDING </option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" onClick={clearFilters}>
                      Clear
                    </Button>
                    <Button variant="primary" onClick={applyFilters}>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <DataTable
          columns={columns}
          //   data={tempData}
          data={tempData}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
        />
      </div>
    </>
  );
};

export default PaymentHistoryPage;
