import "./NewsletterLayout.css";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

const NewsletterPage = () => {
  const [showMail, setShowMail] = useState(false);

  const handleCloseMail = () => setShowMail(false);
  const handleShowMail = () => setShowMail(true);
  const [accordionOpen, setAccordionOpen] = useState(false);

  const columns = [
    {
      name: "SI.No",
      selector: (row) => parseInt(row.id),
      sortable: true,
    },
    {
      name: "Email Id",
      selector: (row) => row.email,
      sortable: true,
      width: "70%",
    },
    {
      name: "View",
      selector: (row) => (
        <div className="newsletter_mail_btn" onClick={handleShowMail}>
          <img src="/build/email.svg" alt="no image" />
        </div>
      ),
      sortable: true,
    },
  ];

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setAccordionOpen(!accordionOpen)
    );
    return (
      <button
        type="button"
        className="admin_filter_btn"
        // style={{ backgroundColor: "pink" }}
        onClick={decoratedOnClick}
      >
        {children}
        {accordionOpen ? (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
          />
        ) : (
          <img
            src="/build/img/Icons/down_vector.svg"
            alt="zex pr wire Logo"
            width={16}
            height={16}
            className="filter_up_arrow"
          />
        )}
      </button>
    );
  }
  return (
    <>
      <div className="newsletter_pages_layout">
        <div className="newsletter_page_heading">
          <h4> Manage Newsletter </h4>{" "}
        </div>
        <Accordion className="mb-4">
          <Card>
            <Card.Header className="d-md-flex justify-content-between align-items-center">
              <div className="staff_search">
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder=""
                    aria-controls="datatable"
                    // onChange={filterData}
                  ></input>
                </label>
              </div>
              <div className="d-md-flex">
                <CustomToggle as={Button} variant="link" eventKey="0">
                  Filters
                </CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <div className="d-flex gap-4 flex-wrap">
                    <Form.Group
                      controlId="filterStatus"
                      className="staff_filter_options"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        // value={filters.status}
                        // onChange={handleFilterChange}
                      >
                        <option value="">All</option>
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="filteremail"
                      className="staff_filter_options"
                    >
                      <Form.Label>Email Id</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Email Id"
                        name="email"
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="mt-3 d-flex gap-3">
                    <Button variant="secondary" /*onClick={clearFilters}*/>
                      Clear
                    </Button>
                    <Button variant="primary" /*onClick={applyFilters} */>
                      Apply
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <DataTable
          columns={columns}
          //   data={tempData}
          data={[{}]}
          pagination
          defaultSortFieldId={1}
          responsive={true}
          defaultSortAsc={true}
        />
      </div>
      <Modal show={showMail} onHide={handleCloseMail}>
        <Modal.Header closeButton>
          <Modal.Title>View Newsletter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Form.Group>
            <Form.Label>Email Id</Form.Label>
            <Form.Control
              type="text"
              placeholder="Email Id"
              name="email"
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Date & Time</Form.Label>
            <Form.Control type="date" name="date"></Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseMail}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewsletterPage;
