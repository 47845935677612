import "./Staff.css";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { Link, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import "./Staff.css";

const StaffDetail = () => {
  const { staffId } = useParams();
  console.log("ID from useParams:", staffId);
  const [staffDetail, setStaffDetail] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStaffDetail = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await Instance.post("/siteadmin/staff/getStaff", {
          adminToken: token,
          staffId: staffId,
          userName: "",
        });
        console.log("staff ID :", staffId);
        setStaffDetail(response.data);
        setLoading(false);
        setError("");
      } catch (error) {
        setError("Failed to fetch staff details");
        setLoading(false);
        console.error("Error fetching staff detail:", error);
      }
    };

    fetchStaffDetail();
  }, [staffId]);

  if (loading) {
    return <p>Loading</p>;
  }

  if (!staffDetail) {
    return <p>No staff details found</p>;
  }

  return (
    <>
      <div className="admin_staff_layout">
        <div className="page_heading">
          <h4>View Staff Details</h4>
          <Link to={"/admin/staff"}>
            <span style={{ cursor: "pointer" }}>
              <div className="backbutton"></div>
            </span>
          </Link>
        </div>
        <div className="personal_details">
          <h5>Staff Details</h5>
          <div className="admin_staff_table">
            <Table className="mb-0 " responsive striped bordered hover>
              <thead className="staff_table_head">
                <tr>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Last Login</th>
                </tr>
              </thead>
              <tbody className="staff_table_body">
                <tr>
                  <td>{staffDetail.userName}</td>
                  <td>{staffDetail.email}</td>
                  <td>{staffDetail.mobile}</td>
                  <td>{staffDetail.lastLoginDate}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="staff_press_release">
          <h5>Edited Press Release</h5>
          <div className="admin_staff_table">
            <Table className="mb-0 " responsive striped bordered hover>
              <thead className="staff_table_head">
                <tr>
                  <th>Title of the Release</th>
                  <th>Status</th>
                  <th>Comment</th>
                  <th>Date & Time</th>
                </tr>
              </thead>
              <tbody className="staff_table_body">
                <tr></tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffDetail;
