import "./AnalyticsLayout.css";
import { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { LineChart } from "@mui/x-charts/LineChart";
import Form from "react-bootstrap/Form";
import { Col } from "react-bootstrap";
import Instance from "../../Utils/Axios";

const PRPage = () => {
  function getOneMonthEarlier(startDate) {
    // Clone the startDate to avoid mutating it
    const newDate = new Date(startDate);

    // Subtract one month
    newDate.setMonth(newDate.getMonth() - 1);

    // Adjust for the end-of-month issues
    if (newDate.getDate() !== startDate.getDate()) {
      newDate.setDate(0);
    }

    return newDate;
  }
  const [dateRange, setDateRange] = useState([
    {
      startDate: getOneMonthEarlier(new Date()),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [error, setError] = useState("");
  const [prList, setPrList] = useState([]);

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const formattedDateRange = `${formatDate(
    dateRange[0].startDate
  )} - ${formatDate(dateRange[0].endDate)}`;

  const handleApplyDateRange = () => {
    setShowDatePicker(false);
  };

  // const TotalPR = [346, 13];
  // const Pending = [0, 0];
  // const Action = [0, 0];
  // const Rejected = [0, 0];
  // const Published = [343, 7];
  // const xLabels = ["June", "July"];

  const TotalPR = [prList?.totalPR?.count, prList?.totalPR?.comparision];
  const Pending = [prList?.PendingPR?.count, prList?.PendingPR?.comparision];
  const Action = [prList?.actionPR?.count, prList?.actionPR?.comparision];
  const Rejected = [prList?.rejectedPR?.count, prList?.rejectedPR?.comparision];
  const Published = [
    prList?.submittedPR?.count,
    prList?.submittedPR?.comparision,
  ];
  const xLabels = [dateRange[0]?.startDate, dateRange[0]?.endDate];

  const fetchPRList = () => {
    const token = localStorage.getItem("adminToken");
    console.log("Fetched token from localStorage:", token);
    Instance.post("/siteadmin/analytics/pr", {
      adminToken: token,
      startDate: dateRange[0]?.startDate,
      endDate: dateRange[0]?.endDate,
    })
      .then((response) => {
        setPrList(response?.data?.message);
        setError("");
        console.log("check data", response?.data?.message);
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching Overivew list:", error);
      });
  };
  useEffect(() => {
    fetchPRList();
  }, [dateRange]);

  console.log("selected date is ", dateRange);
  return (
    <>
      <div className="affiliate_pages_layout">
        <div className="affiliate_page_heading">
          <h4> Analytics - PR</h4>
          <div className="date-picker-container">
            {showDatePicker ? (
              <div className="date-picker-overlay">
                <DateRangePicker
                  ranges={dateRange}
                  onChange={(ranges) => setDateRange([ranges.selection])}
                  onClose={() => setShowDatePicker(false)}
                />
                <button
                  className="apply_date_btn"
                  onClick={handleApplyDateRange}
                >
                  Apply
                </button>
              </div>
            ) : (
              <div
                style={{ textDecoration: "underline" }}
                onClick={() => setShowDatePicker(true)}
              >
                {formattedDateRange}
              </div>
            )}
          </div>
        </div>
        <div className="row   ">
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6  ">
            <div className="card_info" style={{ backgroundColor: "#465aff" }}>
              <div className="card_detail">
                <h5>{prList?.submittedPR?.count}</h5>
                <span>PR SUBMITTED</span>
              </div>
              <div className="card_img">
                <span>
                  <img src="/build/money.png" width={36} height={40} />
                </span>
                <p>
                  -44.53% <FontAwesomeIcon icon={faCircleInfo} />
                </p>
              </div>
              <img className="card_arrow" src="/build/downarrow.svg" />
            </div>
          </div>
          <div className=" col-xl-3 col-lg-3 col-md-3 col-sm-6   ">
            <div className="card_info " style={{ backgroundColor: "#ff326e" }}>
              <div className="card_detail">
                <h5>{prList?.rejectedPR?.count}</h5>
                <span>REJECTION</span>
              </div>
              <div className="card_img">
                <span>
                  <img src="/build/graph.png" width={34} height={38} />
                </span>
                <p>
                  0% <FontAwesomeIcon icon={faCircleInfo} />
                </p>
              </div>
            </div>

            {/* <img className="card_arrow" src="/build/downarrow.svg" /> */}
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6   ">
            <div className="card_info" style={{ backgroundColor: "#fa6d42" }}>
              <div className="card_detail">
                <h5>{prList?.successPR?.count}</h5>
                <span>SUCCESS</span>
              </div>
              <div className="card_img">
                <span>
                  <img src="/build/box.png" width={34} height={38} />
                </span>
                <p>
                  -46.6% <FontAwesomeIcon icon={faCircleInfo} />
                </p>
              </div>
              <img className="card_arrow" src="/build/downarrow.svg" />
            </div>
          </div>
          <div className=" col-xl-3 col-lg-3 col-md-3 col-sm-6  ">
            <div className=" card_info" style={{ backgroundColor: "#5cc858" }}>
              <div className="card_detail">
                <h5>{prList?.totalPR?.count}</h5>
                <span>TOTAL TILL NOW</span>
              </div>
              <div className="card_img">
                <span>
                  <img src="/build/list.png" width={32} height={36} />
                </span>
                {/* <p>
                -42.22% <FontAwesomeIcon icon={faCircleInfo} />
              </p> */}
              </div>

              {/* <img className="card_arrow" src="/build/downarrow.svg" /> */}
            </div>
          </div>
        </div>
        <div className="line-chart-container" style={{ overflow: "auto" }}>
          <div className="d-flex justify-content-between">
            <h5>Analytic Statistics</h5>
            <Col xs="1">
              <Form.Select
                className="analytics_dropdown"
                style={{ backgroundImage: "none" }}
              >
                <option>Select</option>
                <option>Monthly</option>
                <option>Yearly</option>
              </Form.Select>
            </Col>
          </div>
          <LineChart
            width={950}
            height={450}
            series={[
              { data: TotalPR, label: "Total PR" },
              { data: Pending, label: "Pending" },
              { data: Action, label: "Action Required" },
              { data: Rejected, label: "Rejected" },
              { data: Published, label: "Published" },
            ]}
            xAxis={[{ scaleType: "point", data: xLabels }]}
          />
        </div>
      </div>
    </>
  );
};

export default PRPage;
